import type {ReactElement} from 'react';
import {Children, cloneElement} from 'react';

import {Row, spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import type {EyebrowProps} from 'UI/components/layout/ultra/Eyebrow';
import type {HeadlineProps} from 'UI/components/layout/ultra/Headline';

interface HGroupProps {
    /**
     * Controls the horizontal alignment of the children. Can be either 'start' or 'center'.
     */
    align?: 'center' | 'start';
    /**
     * An array containing an Eyebrow component and a Headline component, representing the children elements to be rendered within the heading group container.
     */
    children: [ReactElement<EyebrowProps>, ReactElement<HeadlineProps>];
    /**
     * Specifies the size of the heading group container. Can be either 'large' or 'normal'. Defaults to 'normal' if not provided.
     */
    size?: 'lg' | 'md' | 'sm' | 'xs';
}

/**
 * Renders a heading group container with the specified children elements and optional size.
 * The size property adjusts the alignment and styling of the heading group container accordingly.
 *
 * @param props          The component props.
 * @param props.align    Specifies the alignment of the children. Can be either 'start' or 'center'. Defaults to 'start'.
 * @param props.children An array containing an Eyebrow component and a Headline component, to be rendered within the heading group container.
 * @param props.size     Specifies the size of the heading group container. Can be either 'large' or 'normal'. Defaults to 'normal' if not provided.
 * @returns The rendered heading group component.
 *
 * @example
 * ```tsx
 * <HGroup align="center" size="large">
 *     <Eyebrow>Sample Eyebrow</Eyebrow>
 *     <Headline>Sample Headline</Headline>
 * </HGroup>
 * ```
 */
export const HGroup = ({align, children, size}: HGroupProps) => (
    <HGroupContainer $align={align} direction="column">
        {Children.map(children, child => cloneElement(child, {
            $align: align,
            $size: size
        }))}
    </HGroupContainer>
);

interface HGroupContainerProps {
    $align?: 'center' | 'start';
}

const HGroupContainer = styled(Row)<HGroupContainerProps>`
    flex-grow: 0;
    gap: ${spacing(4)};
    justify-content: ${({$align}) => $align ?? 'start'};
    text-align: ${({$align}) => $align ?? 'start'};
`;