import {Hidden, Spacer, spacing} from '@nfq/react-grid';
import {AnimatePresence, m as motion} from 'framer-motion';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {Button} from 'UI/components/action/ultra/Button';
import {SubnavigationDesktop} from 'UI/components/action/ultra/Subnavigation/SubnavigationDesktop';

import {NavigationItemTransition, NavigationTransition} from 'UI/animations/ultra';

import {useSubnavigation} from './useSubnavigation';
import {PageSections} from 'Application/configs/PageSections';
import {ArrowRightSmall} from 'Images/icons/ultra';
import {Chevron} from 'Images/icons/ultra/Chevron';

/**
 * The subnavigation component for the CCT EVO Ultra campaign.
 *
 * @returns The JSX element for the subnavigation component.
 */
export const Subnavigation = () => {
    const {navigationIsShowing, toggleDropdown} = useSubnavigation();
    const {t} = useTranslation('ultra', {keyPrefix: 'subnavigation'});

    return (
        <>
            <Hidden sm xs>
                <SubnavigationDesktop />
            </Hidden>
            <Hidden lg md xl xxl>
                <>
                    <StyledButton size="small" type="button" variant="secondary" onClick={toggleDropdown}>
                        CCT Evo ultra
                        <IconWrapper $rotate={navigationIsShowing}>
                            <Chevron height={8} />
                        </IconWrapper>
                    </StyledButton>
                    <AnimatePresence>
                        {(navigationIsShowing) && (
                            <StyledNavigation
                                animate="animate"
                                exit="exit"
                                initial="initial"
                                variants={NavigationTransition}
                            >
                                {PageSections.map(section => (
                                    <motion.div
                                        key={section.hash}
                                        variants={NavigationItemTransition}
                                    >
                                        <NavButton
                                            forwardedAs="link"
                                            href={section.hash}
                                            size="small"
                                            variant="secondary"
                                            onClick={toggleDropdown}
                                        >
                                            <ArrowRightSmall height={24} width={24} />
                                            <Spacer x={1.5} />
                                            {t(section.label)}
                                        </NavButton>
                                    </motion.div>
                                ))}
                            </StyledNavigation>
                        )}
                    </AnimatePresence>
                </>
            </Hidden>
        </>
    );
};

const StyledNavigation = styled(motion.div)`
    align-items: flex-start;
    background: ${({theme: globalTheme}) => globalTheme.colors.ultraNavbarNavBackgroundColor};
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    left: 0;
    padding: ${spacing(3.5)} ${spacing(6)} ${spacing(4)};
    position: absolute;
    top: 4.9rem;
    width: 100%;
`;

const StyledButton = styled(Button)`
    background-color: transparent;
    border-radius: .8rem;

    &:focus,
    &:hover {
        background-color: transparent;
        border-color: transparent;
        opacity: .6;
    }

    &:focus-visible {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
    }
`;

const IconWrapper = styled.div<{$rotate: boolean}>`
    align-items: center;
    display: flex;
    transform: rotate(${({$rotate}) => ($rotate ? '0deg' : '180deg')});
    transition: transform .15s ease-in;
`;

const NavButton = styled(Button)`
    background-color: transparent;
    border-radius: .8rem;
    font-weight: normal;
    gap: 0;
    padding: ${spacing(2)};
    padding-left: 0;

    &:focus,
    &:hover {
        border-color: transparent;
        background-color: transparent;
        opacity: .6;
    }

    &:focus-visible {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
    }
`;