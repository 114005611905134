/* eslint-disable max-lines-per-function */
import {Fragment} from 'react';

import {Col, media, Row, Spacer, spacing, Visible} from '@nfq/react-grid';
import Image from 'next/image';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {HoverInfoPopup} from 'UI/components/action/HoverInfoPopup';
import {Button} from 'UI/components/action/ultra/Button';
import {Slider} from 'UI/components/action/ultra/Slider';
import {Colors} from 'UI/components/layout/ultra/Colors';
import {Eyebrow} from 'UI/components/layout/ultra/Eyebrow';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {HGroup} from 'UI/components/layout/ultra/HGroup';
import {Text, TextHighlight} from 'UI/components/layout/ultra/Text';

import {featureIconMap} from 'UI/modules/admin/EditProduct/Features';

import type {Bike} from 'Domain/ultra/entities/Bikes';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps<T extends Bike> {
    bike: T;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * The `DetailsModal` Component.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier, usually in the form of a string, assigned to the component for testing purposes.
 * @param props.bike   The bike to be displayed.
 * @returns A React element representing the `DetailsModal` component.
 * @todo Add true documentation!
 *
 * @example
 * ```tsx
 * const MyComponent = <DetailsModal testId="myTestId">MyComponent</DetailsModal>;
 * ```
 */
const DetailsModal = <T extends Bike>({bike, testId}: ComponentProps<T>) => {
    const {i18n, t: baseData} = useTranslation('ultra', {keyPrefix: 'modals.bikeData'});
    const {t: specHeadlines} = useTranslation('ultra', {keyPrefix: 'modals.bikeData.specsHeadlines'});
    const {t: specData} = useTranslation('ultra', {keyPrefix: 'modals.bikeData.specsElements'});
    const {t: featureT} = useTranslation('ultra', {keyPrefix: 'features'});
    const formatter = Intl.NumberFormat(i18n.language, {
        currency: 'EUR',
        maximumFractionDigits: 0,
        style: 'currency'
    });

    return (
        <Wrapper data-cy={testId}>
            <Row hasNoWrap={['xxl', 'xl']}>
                <Col xl={7} xs={12}>
                    <Slider>
                        <StyledImage alt={`${bike.id} - ${bike.name}`} src={bike.image} />
                    </Slider>
                </Col>
                <Spacer x={{lg: 4, xs: 0}} y={{lg: 0, xs: 3}} isNotStretching />
                <Col xl={5} xs={12}>
                    <div>
                        <HGroup align="start">
                            <Eyebrow>{bike.id}</Eyebrow>
                            <Headline>{bike.name}</Headline>
                        </HGroup>
                        <Icons>
                            {bike.features.map(feature => {
                                // eslint-disable-next-line security/detect-object-injection
                                const Icon = featureIconMap[feature];

                                return (
                                    <HoverInfoPopup key={feature} content={featureT(feature)}>
                                        <Icon strokeWidth={2.19} />
                                    </HoverInfoPopup>
                                );
                            })}
                        </Icons>
                        <Spacer y={{lg: 16, xs: 8}} />
                        <SmallText>{baseData(bike.description)}</SmallText>
                        <Spacer y={{lg: 16, xs: 8}} />
                        <InfoGrid>
                            {bike.info.map(info => (
                                <div key={info.label}>
                                    <SmallText><TextHighlight>{baseData(info.label)}</TextHighlight></SmallText>
                                    {Array.isArray(info.value)
                                        ? (
                                            <>
                                                <Spacer y={3} />
                                                <Colors colors={info.value} />
                                            </>
                                        )
                                        : (
                                            <>
                                                <Spacer y={4} />
                                                <SmallText>{info.value}</SmallText>
                                            </>
                                        )}
                                </div>
                            ))}
                        </InfoGrid>
                        <Spacer y={{lg: 16, xs: 8}} />
                        <Headline $size="sm">{formatter.format(bike.price)}</Headline>
                        <Spacer y={{lg: 10, xs: 6}} />
                        <Button as="link" href={bike.link} target="_blank" width="full">
                            {baseData('ctaLabel')}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Spacer y={{lg: 43, xs: 20}} />
            <Row justify="center">
                <HGroup align="center">
                    <Eyebrow>{baseData('furnishing')}</Eyebrow>
                    <Headline>{baseData('specs')}</Headline>
                </HGroup>
            </Row>
            <Spacer y={{lg: 20, xs: 12}} />
            {bike.specs.map((detail, index, arr) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                    <Headline $align="start" $size="sm">{specHeadlines(detail.headline)}</Headline>
                    <Spacer y={{lg: 16, xs: 8}} />
                    <ItemGrid>
                        {detail.items.map((item, detailIndex) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <GridItem key={detailIndex}>
                                <Text>
                                    <TextHighlight>{specHeadlines(item.title)}</TextHighlight>
                                    <Spacer y={{lg: 8, xs: 4}} />
                                </Text>
                                <ValueWrapper>
                                    {item.value.map((value, itemIndex) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TextLi key={itemIndex}>{specData(value)}</TextLi>
                                    ))}
                                </ValueWrapper>
                            </GridItem>
                        ))}
                    </ItemGrid>
                    {index < arr.length - 1 && (
                        <>
                            <Spacer y={4} />
                            <Visible lg xl xxl>
                                <div>
                                    <Divider />
                                    <Spacer y={20} />
                                </div>
                            </Visible>
                        </>
                    )}
                </Fragment>
            ))}
            <Spacer y={4} />
            <StarText $size="xs">{baseData('startText')}</StarText>
        </Wrapper>
    );
};

DetailsModal.displayName = 'DetailsModal';
DetailsModal.defaultProps = {testId: 'DetailsModal'};

export {DetailsModal};

const Wrapper = styled.div``;

const ItemGrid = styled.div`
    column-gap: ${spacing(5)};
    display: grid;
    grid-auto-rows: max-content max-content 2.4rem;
    grid-template-columns: 1fr;
    width: 100%;

    ${media('md')} {
        grid-template-columns: 1fr 1fr;
    }

    ${media('lg')} {
        grid-auto-rows: max-content max-content 6.4rem;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const GridItem = styled.div`
    display: grid;
    grid-row: span 3;
    grid-template-rows: subgrid;
    padding-inline: ${spacing(4)};
`;

const ValueWrapper = styled.ul`
    margin: 0;
`;

const TextLi = styled(Text).attrs({as: 'li'})`
    font-size: 1.6rem;
    line-height: 2rem;

    ${media('lg')} {
        font-size: 2rem;
        line-height: 2.6rem;
    }
`;

const SmallText = styled(Text)`
    font-size: 1.4rem;
    line-height: 1.8rem;

    ${media('lg')} {
        font-size: 2rem;
        line-height: 2.6rem;
    }
`;

const StyledImage = styled(Image)`
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
`;

const Icons = styled.div`
    display: flex;
    gap: ${spacing(4)};
    padding-block-start: ${spacing(8)};
`;

const InfoGrid = styled.div`
    display: grid;
    gap: ${spacing(8)};
    grid-template-columns: 1fr 1fr;
`;

const Divider = styled.div`
    background-color: rgba(255, 255, 255, 0.30);
    height: 1px;
`;

const StarText = styled(Text)`
    opacity: 0.5;
`;