import {Col, media, Row, spacing} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {Button} from 'UI/components/action/ultra/Button';
import {Subnavigation} from 'UI/components/action/ultra/Subnavigation';

import {useScrollListener} from 'UI/hooks/useScrollListener';

/**
 * The ultra navigation component for the CCT EVO Ultra campaign.
 *
 * @returns The JSX element for the ultra navigation component.
 */
export const UltraNavigation = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'subnavigation'});
    const {isScrollingUp} = useScrollListener();

    return (
        <NavWrapper $isScrollingUp={isScrollingUp}>
            <StyledRow>
                <Col align="center" direction="row" justify="space-between">
                    <Subnavigation />
                    <ActionWrapper>
                        <Button as="link" href="/cct-evo/ultra" size="small" variant="secondary">
                            {t('enquire')}
                        </Button>
                    </ActionWrapper>
                </Col>
            </StyledRow>
        </NavWrapper>
    );
};

const NavWrapper = styled.nav<{$isScrollingUp: boolean}>`
    align-items: center;
    background: ${({theme: globalTheme}) => globalTheme.colors.ultraNavbarNavBackgroundColor};
    display: flex;
    justify-content: center;
    position: sticky;
    top: ${({$isScrollingUp}) => ($isScrollingUp ? '4.8rem' : 0)};
    transition: top .25s ease-in-out;
    z-index: 20;

    ${media('md')} {
        height: 6.2rem;
        top: ${({$isScrollingUp}) => ($isScrollingUp ? '6.2rem' : '0')};
    }
`;

const StyledRow = styled(Row)`
    height: 4.9rem;
    max-width: 171.2rem;
    padding: 0 ${spacing(4)} 0 0;
    position: relative;

    ${media('md')} {
        height: 6.6rem;
        padding: 0 ${spacing(4)};
    }

    ${media('xl')} {
        max-width: 180rem;
        padding: 0 ${spacing(15)};
    }
`;

const ActionWrapper = styled.div`
    a {
        background-color: ${({theme: globalTheme}) => globalTheme.colors.ultraSecondaryColor};
        color: ${({theme: globalTheme}) => globalTheme.colors.secondaryActionFontColor};
        font-size: 1.2rem;
        padding: ${spacing(1.5)} ${spacing(4)};

        &:focus,
        &:hover {
            background-color: ${({theme: globalTheme}) => globalTheme.colors.ultraSecondaryHoverColor};
            border-color: transparent;
            color: ${({theme: globalTheme}) => globalTheme.colors.secondaryActionFontColor};
        }

        &:focus-visible {
            border-color: ${({theme}) => theme.colors.ultraFocusColor};
        }

        ${media('md')} {
            font-size: 1.4rem;
            padding: ${spacing(2.4)} ${spacing(4)};
        }
    }
`;