import {Col, media, Row, spacing} from '@nfq/react-grid';
import {getImageProps} from 'next/image';
import {Trans, useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Section as BaseSection} from 'UI/components/layout/ultra/Section';
import {Text, TextHighlight} from 'UI/components/layout/ultra/Text';

import {QualityBG} from 'Images/ultra';
import {getBackgroundImage} from 'UI/utils/getBackgroundImage';

/**
 * Represents the Ultra Quality section component.
 * Displays the text explaining the quality level of the CCT EVO Ultra bike.
 *
 * @returns JSX element representing the UltraQualitySection component.
 *
 * @example
 * ```tsx
 * <UltraQualitySection />
 * ```
 */
export const UltraQualitySection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page'});

    const {props: {srcSet}} = getImageProps({
        alt: '',
        quality: 100,
        src: QualityBG
    });
    const bg = getBackgroundImage(srcSet);

    return (
        <Section height="full" center>
            <Background $image={bg} />
            <StyledContainer>
                <Row align="center" justify="center">
                    <Col lg={10} xs={12} xxl={8}>
                        <AnimateInView>
                            <Text $size="lg">
                                <Trans components={{highlight: <TextHighlight />}} i18nKey="quality" t={t} />
                            </Text>
                        </AnimateInView>
                    </Col>
                </Row>
            </StyledContainer>
        </Section>
    );
};

const Section = styled(BaseSection)`
    justify-content: center;
    padding-block: ${spacing(32)};
    scroll-margin-top: 4.8rem;
    z-index: 3;

    ${media('md')} {
        scroll-margin-top: 6.2rem;
    }

    ${media('lg')} {
        padding-block: ${spacing(40)};
    }
`;

interface BackgroundProps {
    $image: string;
}

const Background = styled.div<BackgroundProps>`
    background: transparent ${({$image}) => $image} 50% 50%;
    background-size: cover;
    inset: 0;
    position: absolute;
`;

const StyledContainer = styled(Container)`
    position: relative;
`;