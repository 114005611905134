import {useEffect, useRef} from 'react';

import {useInView} from 'framer-motion';
import {useRouter} from 'next/router';

/**
 * Custom hook to remove hash from URL when a specified section goes out of view.
 * It monitors the visibility of the section and clears the hash from the URL if the section is not in view.
 * If the specified section ID matches the current hash in the URL, it sets a timer to remove the hash after a delay.
 *
 * @param sectionId The ID of the section to track visibility. Optional.
 * @returns A ref object that references the wrapper element of the section.
 *
 * @example
 * ```ts
 * const sectionRef = useRemoveHash('sectionId');
 * ```
 */
export const useRemoveHash = (sectionId?: string) => {
    const wrapperRef = useRef<HTMLElement | null>(null);
    const isInView = useInView(wrapperRef);
    const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>();
    const router = useRouter();

    useEffect(() => {
        /**
         * Clears the timer reference.
         */
        const clear = () => {
            clearTimeout(timerRef.current);
        };

        if (!isInView) {
            const [path, id] = router.asPath.split('#');

            if (!id) return clear;

            if (sectionId === id) {
                timerRef.current = setTimeout(() => {
                    void router.push(path, undefined, {scroll: false});
                }, 750);
            }
        }

        return clear;
    }, [router, sectionId, isInView]);

    return wrapperRef;
};