import {media} from '@nfq/react-grid';
import styled from 'styled-components';

import {P} from 'UI/components/layout/Text';

export interface EyebrowProps {
    $align?: 'center' | 'start';
    $size?: 'lg' | 'md' | 'sm' | 'xs';
}

export const Eyebrow = styled(P)<EyebrowProps>`
    color: ${({theme}) => theme.colors.ultraSecondaryColor};
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-size: ${({$size}) => ($size === 'lg' ? '2rem' : '1.6rem')};
    font-weight: 700;
    line-height: 1;
    text-align: ${({$align}) => $align ?? 'inherit' as const};
    text-transform: uppercase;

    ${media('lg')} {
        font-size: 2rem;
    }
`;