import type {ReactNode} from 'react';

import {media, Spacer, spacing} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {ArrowButton} from 'UI/components/action/ultra/ArrowButton';
import {useSlider} from 'UI/components/action/ultra/Slider/useSlider';
import {Text, TextHighlight} from 'UI/components/layout/ultra/Text';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The slider items.
     */
    items: {
        caption: string;
        slide: ReactNode;
    }[];
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Functional component for rendering a color slider with captions and navigation controls.
 *
 * @param props        The component props.
 * @param props.items  An array of items containing captions and slide elements.
 * @param props.testId The unique identifier for testing purposes.
 *
 * @returns JSX element representing the color slider.
 *
 * @example
 * ```tsx
 * <ColorSlider items={[{caption: "Caption", slide: <SlideComponent />}]} testId="color-slider" />
 * ```
 */
export const ColorSlider = ({items, testId}: ComponentProps) => {
    const {
        nextBtnDisabled,
        onNextButtonClick,
        onPrevButtonClick,
        prevBtnDisabled,
        selectedIndex,
        slideRef
    } = useSlider({loop: true});

    const {t} = useTranslation('ultra', {keyPrefix: 'page.colors'});

    return (
        <SliderWrapper data-cy={testId}>
            <Viewport ref={slideRef}>
                <Container>
                    {items.map(({slide}, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Slide key={index}>{slide}</Slide>
                    ))}
                </Container>
            </Viewport>
            {(!nextBtnDisabled || !prevBtnDisabled) && (
                <>
                    <Spacer y={{lg: 10, xs: 6}} />
                    <Controls>
                        <Caption>
                            {t('variant')}{' '}
                            <TextHighlight>{items[Number(selectedIndex)].caption}</TextHighlight>
                        </Caption>
                        <Controls>
                            <ArrowButton direction="prev" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
                                Prev
                            </ArrowButton>
                            <ArrowButton direction="next" disabled={nextBtnDisabled} onClick={onNextButtonClick}>
                                Next
                            </ArrowButton>
                        </Controls>
                    </Controls>
                </>
            )}
        </SliderWrapper>
    );
};

ColorSlider.displayName = 'Slider';
ColorSlider.defaultProps = {testId: 'Slider'};

const SliderWrapper = styled.div`
    --slide-height: 19rem;
    --slide-size: 100%;
    --slide-spacing: 1rem;
    margin: 0 auto;
    width: 100%;
`;

const Viewport = styled.div`
    border-radius: 16px;
    overflow: hidden;

    ${media('md')} {
        border-radius: 32px;
    }
`;

const Slide = styled.div`
    background-color: ${({theme}) => theme.colors.ultraHeaderBackgroundColor};
    flex: 0 0 100%;
    min-width: 0;
    padding-left: 1rem;
`;

const Container = styled.div`
    backface-visibility: hidden;
    display: flex;
    margin-left: -1rem;
    touch-action: pan-y;
`;

const Controls = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing(4)};
    justify-content: flex-end;
`;

const Caption = styled(Text)`
    font-size: 1.6rem;
    line-height: 1.3;

    ${media('lg')} {
        font-size: 2rem;
        line-height: 1.3;
    }
`;