import type {MutableRefObject} from 'react';
import {useCallback, useEffect, useState} from 'react';

import useEmblaCarousel from 'embla-carousel-react';

import type {EmblaCarouselType, EmblaOptionsType} from 'embla-carousel';

/**
 * A hook for creating a slider component with Embla Carousel. It provides the functionality to navigate between slides,
 * and manage the state of the navigation buttons (previous and next) and dot navigation based on the current position
 * in the slider.
 * The hook initializes the carousel, sets up callback functions for button clicks and slider events,
 * and tracks whether the navigation buttons should be disabled.
 *
 * @param options Optional configuration options for the Embla Carousel.
 * @param jumpRef A React ref that should be attached to the jump function for dot navigation.
 * @returns An object containing properties and methods to control the slider:
 * - `nextBtnDisabled`: A boolean indicating if the next button should be disabled.
 * - `onDotButtonClick`: A function to be called when clicking on the dot navigation.
 * - `onNextButtonClick`: A function to be called when the next button is clicked.
 * - `onPrevButtonClick`: A function to be called when the prev button is clicked.
 * - `prevBtnDisabled`: A boolean indicating if the prev button should be disabled.
 * - `scrollSnaps`: An array of snap point positions.
 * - `selectedIndex` The currently selected snap point index.
 * - `slideRef`: A React ref that should be attached to the slider's viewport element.
 *
 * @example
 * ```tsx
 * const {
 *   slideRef,
 *   onPrevButtonClick,
 *   onNextButtonClick,
 *   prevBtnDisabled,
 *   nextBtnDisabled,
 * } = useSlider();
 *
 * return (
 *   <div>
 *     <button onClick={onPrevButtonClick} disabled={prevBtnDisabled}>Prev</button>
 *     <div ref={slideRef}> //slider items here </div>
 *     <button onClick={onNextButtonClick} disabled={nextBtnDisabled}>Next</button>
 *   </div>
 * );
 * ```
 */
export const useSlider = (
    options?: EmblaOptionsType,
    jumpRef?: MutableRefObject<((index: number) => void) | undefined>
) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options);
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

    const onDotButtonClick = useCallback((index: number) => {
        if (!emblaApi) return;
        emblaApi.scrollTo(index);
    }, [emblaApi]);

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollPrev();
    }, [emblaApi]);

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollNext();
    }, [emblaApi]);

    const onInit = useCallback((api: EmblaCarouselType) => {
        setScrollSnaps(api.scrollSnapList());
    }, []);

    const onSelect = useCallback((api: EmblaCarouselType) => {
        setPrevBtnDisabled(!api.canScrollPrev());
        setNextBtnDisabled(!api.canScrollNext());
        setSelectedIndex(api.selectedScrollSnap());
    }, []);

    if (jumpRef) {
        // eslint-disable-next-line no-param-reassign
        jumpRef.current = onDotButtonClick;
    }

    useEffect(() => {
        if (!emblaApi) return;

        onInit(emblaApi);
        onSelect(emblaApi);
        emblaApi.on('reInit', onInit);
        emblaApi.on('reInit', onSelect);
        emblaApi.on('select', onSelect);
    }, [emblaApi, onInit, onSelect]);

    return {
        nextBtnDisabled,
        onDotButtonClick,
        onNextButtonClick,
        onPrevButtonClick,
        prevBtnDisabled,
        scrollSnaps,
        selectedIndex,
        slideRef: emblaRef
    };
};