import type {MutableRefObject} from 'react';
import {Children} from 'react';

import {media, Spacer, spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {ArrowButton} from '../ArrowButton';

import {useSlider} from './useSlider';

import type {WithChildren} from 'types/global';

type AspectRatio = `${number} / ${number}`;

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The aspect ratios at the breakpoints xs, xl and xxl.
     */
    aspectRatio: [AspectRatio, AspectRatio, AspectRatio];
    /**
     * The content to be displayed within the slider. Each child represents an individual slide.
     */
    jumpRef?: MutableRefObject<((index: number) => void) | undefined>;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a slider component that displays its children as individual slides, with navigation controls for previous and next slides.
 * Utilizes a custom hook `useSlider` to manage slider functionality including navigation and disabling state of navigation buttons based on the current slide.
 * Children are mapped into individual `Slide` components and wrapped inside a `Viewport` and `Container` for proper slider functionality.
 * Navigation buttons are conditionally rendered based on the disabled state of the next and previous buttons, which is managed by the `useSlider` hook.
 *
 * @param props             The component props.
 * @param props.children    The content to be displayed within the slider. Each child represents an individual slide.
 * @param props.testId      A unique identifier for testing purposes. It is used to select the component during automated testing.
 * @param props.aspectRatio The aspect ratios at the breakpoints xs, xl and xxl.
 * @param props.jumpRef     A React ref that should be attached to the jump function for dot navigation.
 * @returns A JSX element representing a slider with navigation controls and provided child elements as slides.
 *
 * @example
 * ```tsx
 * <Slider testId="my-slider">
 *   <div>Slide 1</div>
 *   <div>Slide 2</div>
 * </Slider>
 * ```
 */
const Slider = ({aspectRatio, children, jumpRef, testId}: WithChildren<ComponentProps>) => {
    const {nextBtnDisabled, onNextButtonClick, onPrevButtonClick, prevBtnDisabled, slideRef} = useSlider({}, jumpRef);

    return (
        <SliderWrapper data-cy={testId}>
            <Viewport ref={slideRef}>
                <Container>
                    {Children.map(children, (child, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Slide key={index} $aspectRatio={aspectRatio}>
                            {child}
                        </Slide>
                    ))}
                </Container>
            </Viewport>
            {(!nextBtnDisabled || !prevBtnDisabled) && (
                <>
                    <Spacer y={9} />
                    <Controls>
                        <ArrowButton direction="prev" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
                            Prev
                        </ArrowButton>
                        <ArrowButton direction="next" disabled={nextBtnDisabled} onClick={onNextButtonClick}>
                            Next
                        </ArrowButton>
                    </Controls>
                </>
            )}
        </SliderWrapper>
    );
};

Slider.displayName = 'Slider';
Slider.defaultProps = {
    aspectRatio: ['4 / 3', '9 / 8', '4 / 3'],
    testId: 'Slider'
};

export {Slider};

const SliderWrapper = styled.div`
    --slide-height: 19rem;
    --slide-size: 100%;
    --slide-spacing: 1rem;
    margin: 0 auto;
    width: 100%;
`;

const Viewport = styled.div`
    border-radius: 16px;
    overflow: hidden;

    ${media('xl')} {
        border-radius: 32px;
    }
`;

interface SlideProps {
    $aspectRatio: [AspectRatio, AspectRatio, AspectRatio];
}

const Slide = styled.div<SlideProps>`
    aspect-ratio: ${({$aspectRatio}) => $aspectRatio[0]};
    background-color: ${({theme}) => theme.colors.ultraHeaderBackgroundColor};
    flex: 0 0 100%;
    min-width: 0;
    padding-left: 1rem;

    ${media('xl')} {
        aspect-ratio: ${({$aspectRatio}) => $aspectRatio[1]};
    }

    ${media('xxl')} {
        aspect-ratio: ${({$aspectRatio}) => $aspectRatio[2]};
    }
`;

const Container = styled.div`
    backface-visibility: hidden;
    display: flex;
    margin-left: calc(1rem * -1);
    touch-action: pan-y;
`;

const Controls = styled.div`
    display: flex;
    gap: ${spacing(4)};
    justify-content: center;
`;