import {useConfig} from '@nfq/react-grid';

import {useSlider} from 'UI/components/action/ultra/Slider/useSlider';

/**
 * Custom hook for managing the gallery slider functionality and responsive switch.
 *
 * @returns An object containing properties and methods to control the slider:
 * - `nextBtnDisabled`: A boolean indicating if the next button should be disabled.
 * - `onNextButtonClick`: A function to be called when the next button is clicked.
 * - `onPrevButtonClick`: A function to be called when the prev button is clicked.
 * - `prevBtnDisabled`: A boolean indicating if the prev button should be disabled.
 * - `slideRef`: A React ref that should be attached to the slider's viewport element.
 *
 * @example
 * ```ts
 * const { nextBtnDisabled, onNextButtonClick, onPrevButtonClick, prevBtnDisabled, slideRef } = useGallerySlider();
 * ```
 */
export const useGallerySlider = () => {
    const {breakpoints} = useConfig();

    const mediaQuery = `(max-width: ${breakpoints.md - 1}px)`;

    const {nextBtnDisabled, onNextButtonClick, onPrevButtonClick, prevBtnDisabled, slideRef} = useSlider({
        active: true,
        [mediaQuery]: {active: false}
    });

    return {
        nextBtnDisabled,
        onNextButtonClick,
        onPrevButtonClick,
        prevBtnDisabled,
        slideRef
    };
};