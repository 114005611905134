/* eslint-disable react/jsx-props-no-spreading */

import {SHOW_ULTRA_PAGE} from '@app/features';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

import {UltraPageLayout} from 'UI/layouts/ultra/UltraPageLayout';
import {UltraPage} from 'UI/modules/ultra/UltraPage';

import {ULTRA_LAUNCH_DATE} from 'UI/utils/constants';

import type {GetServerSideProps} from 'next';
import type {SSRConfig} from 'next-i18next';
import type {NextSSRPageWithLayout} from 'types/global';

/**
 * The corratec Ultra teaser page component.
 *
 * @param props                   The component props.
 * @param props.currentDateString The current date returned by the server.
 * @returns A ReactNode representing the `TeaserPage` page.
 */
const UltraLandingPage: NextSSRPageWithLayout<typeof getServerSideProps> = ({currentDateString}) => {
    const currDate = new Date(currentDateString);

    if (currDate >= ULTRA_LAUNCH_DATE || SHOW_ULTRA_PAGE) {
        return <UltraPage />;
    }

    return null;
};

UltraLandingPage.getLayout = (router, pageProps, PageComponent) => (
    <UltraPageLayout title="index">
        <PageComponent router={router} {...pageProps} />
    </UltraPageLayout>
);

UltraLandingPage.getLayoutKey = () => 'Ultra';

export default UltraLandingPage;

interface ServerSideProps extends SSRConfig {
    currentDateString: string;
}

/**
 * Fetches the necessary data for the `TeaserPage` component.
 *
 * @param context        The context object contains parameters of the server-side page, including `params` which holds the dynamic segments of the route.
 * @param context.locale The locale of the page.
 * @returns A promise resolving to an object containing the `props` to be passed to the page component.
 */
export const getServerSideProps: GetServerSideProps<ServerSideProps> = async ({locale}) => {
    const translations = await serverSideTranslations(locale ?? 'de', ['common', 'ultra']);
    const currentDate = new Date();

    return Promise.resolve({
        props: {
            ...translations,
            currentDateString: currentDate.toISOString()
        }
    });
};