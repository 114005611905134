/* eslint-disable max-len */
import React from 'react';

interface ArrowRightProps {
    /**
     * A string representing the CSS class to be applied to the ArrowRightIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the ArrowRightIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the ArrowRightIcon element.
     */
    width?: number | string;
}

/**
 * The `ArrowRight` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ArrowRightProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the ArrowRightIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the ArrowRightIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the ArrowRightIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ArrowRightComponent = <ArrowRight className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const ArrowRight = React.forwardRef<SVGSVGElement, ArrowRightProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 22 16"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M1 7a1 1 0 0 0 0 2zm20.7 1.7a1 1 0 0 0 0-1.4L15.35.92a1 1 0 1 0-1.41 1.41L19.59 8l-5.66 5.66a1 1 0 0 0 1.41 1.41zM1 9h20V7H1z" fill={color1} />
        </svg>
    );
});

ArrowRight.displayName = 'ArrowRight';
ArrowRight.defaultProps = {
    className: '',
    color1: '#fff',
    height: 16,
    testId: 'ArrowRight',
    width: 22
};