export const HeroVideos = [
    {
        media: '(orientation: landscape) and (min-width: 3000px)',
        src: '/videos/ultra/Hero_BG_Desktop_4K_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(orientation: landscape) and (min-width: 3000px)',
        src: '/videos/ultra/Hero_BG_Desktop_4K.mp4'
    },
    {
        media: '(orientation: landscape) and (min-width: 2000px)',
        src: '/videos/ultra/Hero_BG_Desktop_2K_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(orientation: landscape) and (min-width: 2000px)',
        src: '/videos/ultra/Hero_BG_Desktop_2K.mp4'
    },
    {
        media: '(orientation: landscape) and (min-width: 1400px)',
        src: '/videos/ultra/Hero_BG_Desktop_FHD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(orientation: landscape) and (min-width: 1400px)',
        src: '/videos/ultra/Hero_BG_Desktop_FHD.mp4'
    },
    {
        media: '(orientation: landscape) and (min-width: 1000px)',
        src: '/videos/ultra/Hero_BG_Desktop_HD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(orientation: landscape) and (min-width: 1000px)',
        src: '/videos/ultra/Hero_BG_Desktop_HD.mp4'
    },
    {
        media: '(orientation: portrait) and (min-width: 800px)',
        src: '/videos/ultra/Hero_BG_FHD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(orientation: landscape) and (min-width: 800px)',
        src: '/videos/ultra/Hero_BG_FHD.mp4'
    },
    {
        media: '(orientation: portrait)',
        src: '/videos/ultra/Hero_BG_HD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(orientation: landscape)',
        src: '/videos/ultra/Hero_BG_HD.mp4'
    },
    {
        src: '/videos/ultra/Hero_BG_Desktop_SD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {src: '/videos/ultra/Hero_BG_Desktop_SD.mp4'}
];