import styled from 'styled-components';

import {ViewportVideo} from 'UI/components/video/ViewportVideo';

export const BackgroundVideo = styled(ViewportVideo)`
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
`;