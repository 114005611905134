import type {HTMLProps} from 'react';

import {media, spacing} from '@nfq/react-grid';
import styled, {css} from 'styled-components';

import {useRemoveHash} from './useRemoveHash';

import type {WithChildren} from 'types/global';

type SectionProps = HTMLProps<HTMLElement> & {
    center?: boolean;
    height?: 'auto' | 'full';
};

/**
 * Functional component to render a section with optional centering and height properties.
 *
 * @param props          The component props.
 * @param props.children The content of the section.
 * @param props.center   Specifies if the section should be centered horizontally. Optional.
 * @param props.height   Specifies the height of the section. Optional.
 * @returns JSX element representing the section.
 *
 * @example
 * ```tsx
 * <Section center height="300px">
 *    <Content />
 * </Section>
 * ```
 */
export const Section = ({
    center,
    children,
    height,
    ...props
}: WithChildren<SectionProps>) => {
    const wrapperRef = useRemoveHash(props.id);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Wrapper ref={wrapperRef} $center={center} $height={height} {...props}>
            {children}
        </Wrapper>
    );
};

interface WrapperProps {
    $center?: boolean;
    $height?: 'auto' | 'full';
}

const Wrapper = styled.section<WrapperProps>`
    ${({$center}) => ($center ? css`
        align-items: center;
        display: flex;
        justify-content: center;
    ` : undefined)}
    ${({$height}) => ($height === 'full' ? 'min-height: 100vh' : 'min-height: auto')};
    background: linear-gradient(270deg, #2D2C2D 0%, #000000 100%);
    overflow: hidden;
    padding-block: ${spacing(15)};
    padding-inline: ${spacing(4)};
    position: relative;

    ${media('md')} {
        padding-inline: ${spacing(15)};
        padding-block: ${spacing(15)};
    }

    ${media('lg')} {
        padding-block: ${spacing(30)};
    }

    ${media('xl')} {
        padding-block: ${spacing(40)};
    }
`;