import {useScreenSize} from '@nfq/react-grid';
import Image from 'next/image';
import styled from 'styled-components';

interface StaticImageData {
    height: number;
    src: string;
    width: number;
}

export interface StaticRequire {
    default: StaticImageData;
}

export type StaticImport = StaticImageData | StaticRequire;

interface ComponentProps {
    /** The source for desktop view. */
    desktopSrc: StaticImport | string;
    /** The source for mobile view. */
    mobileSrc: StaticImport | string;
}

/**
 * Functional component for displaying a responsive slider image based on screen size.
 * It renders different images for different breakpoints.
 *
 * @param props            The component props.
 * @param props.desktopSrc The source URL for desktop view.
 * @param props.mobileSrc  The source URL for mobile view.
 *
 * @returns JSX element representing the responsive slider image.
 *
 * @example
 * ```tsx
 * <ResponsiveSliderImage desktopSrc="/path/to/desktop/image.jpg" mobileSrc="/path/to/mobile/image.jpg" />
 * ```
 */
export const ResponsiveSliderImage = ({desktopSrc, mobileSrc}: ComponentProps) => {
    const breakpoint = useScreenSize();

    switch (breakpoint) {
        case 'xxl':
        case 'xl':
        case 'lg':
        case 'md':
            return <SliderImage alt="" src={desktopSrc} unoptimized />;
        default:
            return <SliderImage alt="" src={mobileSrc} unoptimized />;
    }
};

const SliderImage = styled(Image)`
    display: block;
    height: auto;
    width: 100%;
`;