export const ForkVideos = [
    {
        media: '(min-width: 2000px)',
        src: '/videos/ultra/Fork_Detail_2K.mp4'
    },
    {
        media: '(min-width: 1400px)',
        src: '/videos/ultra/Fork_Detail_FHD.mp4'
    },
    {
        media: '(min-width: 1000px)',
        src: '/videos/ultra/Fork_Detail_HD.mp4'
    },
    {src: '/videos/ultra/Fork_Detail_SD.mp4'}
];