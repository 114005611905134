/** The number of milliseconds in one second. */
export const SECOND = 1000;

/** The number of milliseconds in one minute. */
// eslint-disable-next-line @nfq/no-magic-numbers
export const MINUTE = 60 * SECOND;

/** The number of milliseconds in one hour. */
// eslint-disable-next-line @nfq/no-magic-numbers
export const HOUR = 60 * MINUTE;

/** The number of milliseconds in a day. */
// eslint-disable-next-line @nfq/no-magic-numbers
export const DAY = 24 * HOUR;

export const ADVENT_CALENDAR_START = '2023-12-01T10:00:00+01:00';
export const ADVENT_CALENDAR_END = '2023-12-25T10:00:00+01:00';

export const ULTRA_LAUNCH_DATE = new Date('2024-03-21T15:00:00+01:00');