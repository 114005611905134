import {useConfig} from '@nfq/react-grid';

import {useSlider} from 'UI/components/action/ultra/Slider/useSlider';

/**
 * Custom hook for EvoFamilySlider component to manage slider behavior depending on breakpoints.
 * This hook retrieves breakpoints from the global configuration, constructs a media query string,
 * and returns the slider configuration using the `useSlider` hook with specified breakpoints.
 *
 * @returns An object containing properties and methods to control the slider:
 * - `onDotButtonClick`: A function to be called when clicking on the dot navigation.
 * - `scrollSnaps`: An array of snap point positions.
 * - `selectedIndex` The currently selected snap point index.
 * - `slideRef`: A React ref that should be attached to the slider's viewport element.
 *
 * @example
 * ```ts
 * const {onDotButtonClick, scrollSnaps, selectedIndex, slideRef} = useEvoFamilySlider();
 * ```
 */
export const useEvoFamilySlider = () => {
    const {breakpoints} = useConfig();

    const mediaQuery = `(min-width: ${breakpoints.md}px)`;

    const {
        onDotButtonClick,
        scrollSnaps,
        selectedIndex,
        slideRef
    } = useSlider({breakpoints: {[mediaQuery]: {watchDrag: false}}});

    return {
        onDotButtonClick,
        scrollSnaps,
        selectedIndex,
        slideRef
    };
};