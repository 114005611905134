import {Children} from 'react';

import {Col, media, Spacer, spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {ArrowButton} from 'UI/components/action/ultra/ArrowButton';
import {Container as BaseContainer} from 'UI/components/layout/ultra/Container';
import {useGallerySlider} from 'UI/components/layout/ultra/GallerySlider/useGallerySlider';

import type {WithChildren} from 'types/global';

/**
 * Gallery slider component.
 * This component renders a slider for gallery items, including next and previous buttons for navigation.
 *
 * @param props          The component props.
 * @param props.children The children to be rendered as the slides within the slider.
 *
 * @returns The rendered gallery slider component.
 *
 * @example
 * ```tsx
 * <GallerySlider>
 *    {/* Gallery items here *\/}
 * </GallerySlider>
 * ```
 */
export const GallerySlider = ({children}: WithChildren) => {
    const {nextBtnDisabled, onNextButtonClick, onPrevButtonClick, prevBtnDisabled, slideRef} = useGallerySlider();

    return (
        <SliderWrapper>
            <Viewport ref={slideRef}>
                <Container>
                    <Col lg={2} xs={0} />
                    {Children.map(children, (child, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Slide key={index} lg={6} md={8} xs={12}>
                            {child}
                        </Slide>
                    ))}
                </Container>
            </Viewport>
            {(!nextBtnDisabled || !prevBtnDisabled) && (
                <BaseContainer>
                    <Spacer y={9} />
                    <Controls>
                        <ArrowButton direction="prev" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
                            Prev
                        </ArrowButton>
                        <ArrowButton direction="next" disabled={nextBtnDisabled} onClick={onNextButtonClick}>
                            Next
                        </ArrowButton>
                    </Controls>
                </BaseContainer>
            )}
        </SliderWrapper>
    );
};

const SliderWrapper = styled.div`
    left: -2.6rem;
    margin: auto;
    position: relative;
    width: calc(100% + 4.2rem);

    ${media('md')} {
        left: 0;
        width: 100%;
    }
`;
const Viewport = styled.div`
    border-radius: 32px;
    overflow: visible;

`;
const Container = styled(BaseContainer)`
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    gap: ${spacing(4)};
    margin-left: auto;
    touch-action: pan-y;

    ${media('md')} {
        flex-direction: row;
        gap: auto;
    }
`;
const Slide = styled(Col)`
    min-width: 0;
    padding-left: 1rem;
`;

const Controls = styled.div`
    display: flex;
    gap: ${spacing(5)};
    justify-content: end;
`;