import {Col, Row, Spacer} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';

import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {ColorSlider} from 'UI/components/layout/ultra/ColorSlider';
import {ResponsiveSliderImage} from 'UI/components/layout/ultra/ColorSlider/ResponsiveSliderImage';
import {Container} from 'UI/components/layout/ultra/Container';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {Section} from 'UI/components/layout/ultra/Section';
import {Text} from 'UI/components/layout/ultra/Text';

import {
    ColorSlideBlack,
    ColorSlideBlackDesktop,
    ColorSlideBlue,
    ColorSlideBlueDesktop,
    ColorSlideCopper,
    ColorSlideCopperDesktop,
    ColorSlideCover,
    ColorSlideCoverDesktop,
    ColorSlideSilver,
    ColorSlideSilverDesktop
} from 'Images/ultra';

/**
 * Functional component for rendering the color section with responsive slider images.
 * It displays various color slides with captions.
 *
 * @returns JSX element representing the color section.
 *
 * @example
 * ```tsx
 * <ColorSection />
 * ```
 */
export const ColorSection = () => {
    const {t} = useTranslation('ultra');

    const items = [
        {
            caption: t('page.colors.mix'),
            slide: <ResponsiveSliderImage desktopSrc={ColorSlideCoverDesktop} mobileSrc={ColorSlideCover} />
        },
        {
            caption: t('colors.blue'),
            slide: <ResponsiveSliderImage desktopSrc={ColorSlideBlueDesktop} mobileSrc={ColorSlideBlue} />
        },
        {
            caption: t('colors.black'),
            slide: <ResponsiveSliderImage desktopSrc={ColorSlideBlackDesktop} mobileSrc={ColorSlideBlack} />
        },
        {
            caption: t('colors.copper'),
            slide: <ResponsiveSliderImage desktopSrc={ColorSlideCopperDesktop} mobileSrc={ColorSlideCopper} />
        },
        {
            caption: t('colors.silver'),
            slide: <ResponsiveSliderImage desktopSrc={ColorSlideSilverDesktop} mobileSrc={ColorSlideSilver} />
        }
    ];

    return (
        <Section>
            <Container>
                <Row justify="center">
                    <Col lg={10}>
                        <AnimateInView>
                            <Headline $align="center">{t('page.colors.headline')}</Headline>
                        </AnimateInView>
                        <Spacer y={{lg: 12, xs: 8}} />
                        <AnimateInView>
                            <Text $align="center">{t('page.colors.description')}</Text>
                        </AnimateInView>
                        <Spacer y={{lg: 40, xs: 20}} />
                        <AnimateInView>
                            <ColorSlider items={items} />
                        </AnimateInView>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};