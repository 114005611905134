import styled from 'styled-components';

import {HoverInfoPopup} from 'UI/components/action/HoverInfoPopup';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    colors: {
        color: string;
        name: string;
    }[];
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * The `Colors` Component.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier, usually in the form of a string, assigned to the component for testing purposes.
 * @param props.colors The colors to be displayed.
 * @returns A React element representing the `Colors` component.
 * @todo Add true documentation!
 *
 * @example
 * ```tsx
 * const MyComponent = <Colors testId="myTestId">MyComponent</Colors>;
 * ```
 */
const Colors = ({colors, testId}: ComponentProps) => (
    <Wrapper data-cy={testId}>
        {colors.map(({color, name}) => (
            <HoverInfoPopup key={name} content={name}>
                <Color $color={color} />
            </HoverInfoPopup>
        ))}
    </Wrapper>
);

Colors.displayName = 'Colors';
Colors.defaultProps = {testId: 'Colors'};

export {Colors};

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
`;

interface ColorProps {
    $color: string;
}

const Color = styled.div<ColorProps>`
    background-color: #000000;
    border-radius: 50%;
    height: 22px;
    width: 22px;

    &::after {
        background-color: ${({$color}) => $color};
        border-radius: 0 11px 11px 0;
        content: '';
        display: block;
        height: 22px;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 11px;
    }
`;