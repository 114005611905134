import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {VideoFadeAnimation} from 'UI/animations/ultra';

import {useViewportVideo} from './useViewportVideo';

import type {MediaItem} from 'Domain/ultra/entities/MediaItem';

interface ComponentProps {
    /**
     * Optional class name for styling purposes.
     */
    className?: string;
    /**
     * An array of media items containing information about the videos to be displayed.
     */
    items: MediaItem[];
    /**
     * Flag indicating whether the video should loop.
     */
    loop?: boolean;
}

/**
 * React component for displaying a video triggered when it enters the viewport.
 * It utilizes the `useViewportVideo` hook to manage video behavior.
 *
 * @param props           The component props.
 * @param props.className Optional class name for styling purposes.
 * @param props.items     An array of media items containing information about the videos to be displayed.
 * @param props.loop      Flag indicating whether the video should loop.
 * @returns A React component representing the viewport-triggered video.
 * @example
 * ```tsx
 * <ViewportVideo className="video-container" items={mediaItems} loop />
 * ```
 */
export const ViewportVideo = ({className, items, loop}: ComponentProps) => {
    const {
        animation,
        handleCanPlayThrough,
        handleViewportEnter,
        mediaItem,
        videoRef
    } = useViewportVideo(items);

    return (
        <Video
            ref={videoRef}
            animate={animation}
            className={className}
            loop={loop}
            src={mediaItem?.src}
            variants={VideoFadeAnimation}
            muted
            playsInline
            onCanPlayThrough={handleCanPlayThrough}
            onViewportEnter={handleViewportEnter}
        />
    );
};

const Video = styled(motion.video)`
    display: block;
    height: auto;
    width: 100%;
`;