import {Newsletter} from 'UI/components/action/ultra/Newsletter/Newsletter';

import {UltraFooter} from 'UI/layouts/ultra/UltraFooter';
import {UltraHeader} from 'UI/layouts/ultra/UltraHeader';
import {UltraNavigation} from 'UI/layouts/ultra/UltraNavigation';
import {AccessorySection} from 'UI/modules/ultra/sections/page/AccessorySection';
import {AeroSection} from 'UI/modules/ultra/sections/page/AeroSection';
import {ColorSection} from 'UI/modules/ultra/sections/page/ColorSection';
import {DanNoyolaSection} from 'UI/modules/ultra/sections/page/DanNoyolaSection';
import {EvoFamilySection} from 'UI/modules/ultra/sections/page/EvoFamilySection';
import {FrameDetailSection} from 'UI/modules/ultra/sections/page/FrameDetailSection';
import {FrontDetailSection} from 'UI/modules/ultra/sections/page/FrontDetailSection';
import {HandlebarSection} from 'UI/modules/ultra/sections/page/HandlebarSection';
import {HeroSection} from 'UI/modules/ultra/sections/page/HeroSection';
import {PartsSection} from 'UI/modules/ultra/sections/page/PartsSection';
import {TurntableSection} from 'UI/modules/ultra/sections/page/TurntableSection';
import {UltraQualitySection} from 'UI/modules/ultra/sections/page/UltraQualitySection';
import {UniqueSection} from 'UI/modules/ultra/sections/page/UniqueSection';
import {VideoSection} from 'UI/modules/ultra/sections/page/VideoSection';

/**
 * The landing page component for the CCT EVO Ultra campaign. It combines the different sections making up the landing page.
 *
 * @returns The JSX element for the UltraPage component.
 */
export const UltraPage = () => (
    <>
        <UltraHeader />
        <UltraNavigation />
        <HeroSection />
        <AeroSection />
        <FrameDetailSection />
        <FrontDetailSection />
        <UniqueSection />
        <TurntableSection />
        <UltraQualitySection />
        <HandlebarSection />
        <ColorSection />
        <PartsSection />
        <VideoSection />
        <DanNoyolaSection />
        <AccessorySection />
        <EvoFamilySection />
        <Newsletter />
        <UltraFooter />
    </>
);