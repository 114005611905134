import {Col, media, Row, Spacer, spacing} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {H2, H3} from 'UI/components/layout/Text';
import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {Section} from 'UI/components/layout/ultra/Section';
import {Text} from 'UI/components/layout/ultra/Text';

/**
 * Represents the Parts section component displaying information about various parts of CCT EVO Ultra.
 * It renders a section with headings and text describing different parts like frame, cockpit, and fork.
 *
 * @returns JSX element representing the PartsSection component.
 *
 * @example
 * ```tsx
 * <PartsSection />
 * ```
 */
export const PartsSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.parts'});

    return (
        <Section>
            <Container>
                <Row justify="center">
                    <Col lg={10} xs={12}>
                        <AnimateInView>
                            <Headline as={H2}>{t('headline')}</Headline>
                        </AnimateInView>
                        <Spacer y={{lg: 20, xs: 10}} />
                        <AnimateInView>
                            <Grid>
                                <GridCol>
                                    <ColHeading>{t('frame.headline')}</ColHeading>
                                    <Spacer y={{lg: 8, xs: 6}} isNotStretching />
                                    <Text>{t('frame.text')}</Text>
                                </GridCol>
                                <GridCol>
                                    <ColHeading>{t('cockpit.headline')}</ColHeading>
                                    <Spacer y={{lg: 8, xs: 6}} isNotStretching />
                                    <Text>{t('cockpit.text')}</Text>
                                </GridCol>
                                <GridCol>
                                    <ColHeading>{t('fork.headline')}</ColHeading>
                                    <Spacer y={{lg: 8, xs: 6}} isNotStretching />
                                    <Text>{t('fork.text')}</Text>
                                </GridCol>
                            </Grid>
                        </AnimateInView>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

const Grid = styled.div`
    column-gap: ${spacing(5)};
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${spacing(6)};

    ${media('md')} {
        grid-template-columns: repeat(2, 1fr);
        row-gap: ${spacing(12)};
    }

    ${media('lg')} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const GridCol = styled.div`
    padding: 0 ${spacing(4)};
`;

const ColHeading = styled(Headline).attrs({forwardedAs: H3})`
    font-size: 1.6rem;

    ${media('md')} {
        font-size: 2rem;
    }
`;