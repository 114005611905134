import {useEffect, useRef, useState} from 'react';

import {useDebounce} from 'UI/hooks/useDebounce';

import type {MediaItem} from 'Domain/ultra/entities/MediaItem';

/**
 * Finds a fallback in a list of media items.
 *
 * @param mediaItems The list of media items.
 * @returns The fallback media item.
 */
const findFallback = (mediaItems: MediaItem[]) => {
    const fallback = mediaItems.find(el => !('media' in el));

    if (fallback) return fallback;

    return mediaItems[mediaItems.length - 1];
};

/**
 * A hook for managing responsive videos based on the provided list of media items.
 * This hook dynamically selects the appropriate media item based on the current window size and video element capabilities.
 * It listens for window resize events to ensure the selected media item is always suitable for the current viewport.
 *
 * @param mediaItems An array of media items representing different video sources and optionally their corresponding media queries and MIME types. Objects have to be sorted analogous to the `<source />` elements in a `<picture />` element, so the best match is the top-most element in the list.
 * @returns A tuple containing the selected media item and a reference to the video element.
 *
 * @example
 * ```tsx
 * const [mediaItem, videoRef] = useResponsiveVideo([
 *     { src: 'video_hd.mp4', media: '(min-width: 1440px)', type: 'video/mp4' },
 *     { src: 'video_hd.webm', media: '(min-width: 1440px)', type: 'video/webm' },
 *     { src: 'video.mp4' },
 * ]);
 *
 * return (
 *     <video ref={videoRef} controls>
 *         <source src={mediaItem?.src} type={mediaItem?.type} />
 *         Your browser does not support the video tag.
 *     </video>
 * );
 * ```
 */
export const useResponsiveVideo = (mediaItems: MediaItem[]) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [mediaItem, setMediaItem] = useState<MediaItem>();

    /**
     * The handler for the window `resize` event tries to find the best matching media item from the provided list.
     */
    const resizeHandler = useDebounce(() => {
        const videoEl = videoRef.current;

        if (videoEl) {
            const item = mediaItems.find(el => {
                // eslint-disable-next-line react-hooks-ssr/react-hooks-global-ssr
                const matchesMediaQuery = el.media && window.matchMedia(el.media).matches;

                if (!matchesMediaQuery) return false;

                return typeof el.type === 'undefined' || (videoEl.canPlayType(el.type) === 'probably');
            });

            if (item) {
                setMediaItem(item);
                return;
            }
        }

        const fallback = findFallback(mediaItems);

        setMediaItem(fallback);
    });

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);

        resizeHandler();

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [mediaItems, resizeHandler]);

    return {
        mediaItem,
        videoRef
    };
};