import {Col, media, Row, Spacer} from '@nfq/react-grid';
import Image from 'next/image';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {H2} from 'UI/components/layout/Text';
import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Eyebrow} from 'UI/components/layout/ultra/Eyebrow';
import {GallerySlider} from 'UI/components/layout/ultra/GallerySlider';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {HGroup} from 'UI/components/layout/ultra/HGroup';
import {ImageGrid} from 'UI/components/layout/ultra/ImageGrid';
import {Section} from 'UI/components/layout/ultra/Section';
import {Text} from 'UI/components/layout/ultra/Text';

import {DanNoyolaSlide01, DanNoyolaSlide02, DanNoyolaSlide03, DanNoyolaSlide04, DanNoyolaSlide05} from 'Images/ultra';

/**
 * Represents the Dan Noyola section component.
 * This component represents the section dedicated to Dan Noyola, including headline, gallery slider, quote, and image grid.
 *
 * @returns The JSX element for the HeroSection component.
 *
 * @example
 * ```tsx
 * <DanNoyolaSection />
 * ```
 */
export const DanNoyolaSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.danNoyola'});

    return (
        <Section>
            <Container>
                <Row justify="center">
                    <Col lg={8} xs={12}>
                        <AnimateInView>
                            <HGroup align="center">
                                <Eyebrow>{t('eyebrow')}</Eyebrow>
                                <Headline as={H2}>
                                    <Q>{t('headline')}</Q>
                                </Headline>
                            </HGroup>
                        </AnimateInView>
                    </Col>
                </Row>
            </Container>
            <Spacer y={{lg: 60, xl: 80, xs: 30}} />
            <GallerySlider>
                <GalleryImageWrapper>
                    <GalleryImage alt="" src={DanNoyolaSlide01} />
                </GalleryImageWrapper>
                <GalleryImageWrapper>
                    <GalleryImage alt="" src={DanNoyolaSlide02} />
                </GalleryImageWrapper>
                <GalleryImageWrapper>
                    <GalleryImage alt="" src={DanNoyolaSlide03} />
                </GalleryImageWrapper>
                <GalleryImageWrapper>
                    <GalleryImage alt="" src={DanNoyolaSlide04} />
                </GalleryImageWrapper>
                <GalleryImageWrapper>
                    <GalleryImage alt="" src={DanNoyolaSlide05} />
                </GalleryImageWrapper>
            </GallerySlider>
            <Spacer y={{lg: 40, xs: 30}} />
            <Container>
                <Row justify="center">
                    <Col lg={8} xs={12}>
                        <AnimateInView>
                            <Blockquote>
                                <Text $align="center">{t('quote')}</Text>
                            </Blockquote>
                            <Spacer y={{md: 10, xs: 6}} />
                            <StyledText $align="center">&mdash; {t('fullname')}</StyledText>
                            <Text $align="center">{t('position')}</Text>
                        </AnimateInView>
                    </Col>
                </Row>
            </Container>
            <Spacer y={{lg: 60, xl: 80, xs: 30}} />
            <Container>
                <ImageGrid />
            </Container>
        </Section>
    );
};

const Q = styled.q``;

const Blockquote = styled.blockquote`
    margin: 0;

    ${Text}:before {
        content: open-quote;
    }

    ${Text}:after {
        content: close-quote;
    }
`;

const GalleryImageWrapper = styled.div`
    ${media('md')} {
        border-radius: 32px;
        overflow: hidden;
    }
`;

const GalleryImage = styled(Image)`
    display: block;
    height: auto;
    width: 100%;
`;

const StyledText = styled(Text)`
    color: ${({theme}) => theme.colors.ultraPageForeground};
`;