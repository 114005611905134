// eslint-disable-next-line import/extensions
import Factory from 'Images/ultra/CCTEVOFactory.png';

import type {Bike} from 'Domain/ultra/entities/Bikes';

export const BK29100: Bike = {
    description: 'BK29100description',
    features: ['WHEELS_28', 'DI2_READY', 'TAP_HEADTUBE', 'INT_CABLE', 'THRU_AXLE'],
    id: 'BK29100',
    image: Factory,
    info: [
        {
            label: 'materialLabel',
            value: 'Carbon'
        },
        {
            label: 'weightLabel',
            value: '7,3 KG'
        },
        {
            label: 'colorsLabel',
            value: [
                {
                    color: '#0E62B7',
                    name: 'Pearlized Black / Reflex Blue'
                },
                {
                    color: '#B25C42',
                    name: 'Pearlized Black / Copper'
                }
            ]
        },
        {
            label: 'frameLabel',
            value: '46 | 48 | 51 | 54 | 57 | 60'
        }
    ],
    link: 'https://www.corratec.com/bikes/road/race-carbon/bike/cct-evo-factory-bk29100/',
    name: 'CCT EVO Factory',
    price: 10999,
    specs: [
        {
            headline: 'baseLabel',
            items: [
                {
                    title: 'baseFrameLabel',
                    value: ['cctEvoCarbon']
                },
                {
                    title: 'baseForkLabel',
                    value: ['cctProFork']
                },
                {
                    title: 'baseBrakesLabel',
                    value: ['sramRed160Front', 'sramRed160Rear']
                },
                {
                    title: 'baseStemLabel',
                    value: ['stemCarbonInt']
                },
                {
                    title: 'baseHandlebarLabel',
                    value: ['roadCarbonHandle']
                },
                {
                    title: 'baseSaddleLabel',
                    value: ['slrCarbon']
                },
                {
                    title: 'baseSeatpostLabel',
                    value: ['carbonAero']
                }
            ]
        },
        {
            headline: 'wheelsLabel',
            items: [
                {
                    title: 'wheelsRimLabel',
                    value: ['rim700cFront', 'rim700cRear']
                },
                {
                    title: 'wheelsTireLabel',
                    value: ['tire700x26Front', 'tire700x26Rear']
                }
            ]
        },
        {
            headline: 'driveLabel',
            items: [
                {
                    title: 'driveCrankLabel',
                    value: ['crankSramD1172']
                },
                {
                    title: 'driveCassetteLabel',
                    value: ['cassetteSramXG1290']
                },
                {
                    title: 'driveFrontDerailleurLabel',
                    value: ['frontDerailleurSramRed']
                },
                {
                    title: 'driveRearDerailleurLabel',
                    value: ['rearDerailleurSramRed']
                },
                {
                    title: 'driveShifterLabel',
                    value: ['shifterSramRed']
                }
            ]
        }
    ]
};