import {useState} from 'react';

/**
 * Custom hook to encapsulate the logic for fading in the `<video />` element once it can play through.
 *
 * @returns An object containing the animation name for the Framer Motion transition and an event handler for the `canPlayThrough` event.
 */
export const useVideoAnimation = () => {
    const [showVideo, setShowVideo] = useState(false);

    /**
     * Event handler for the `canPlayThrough` event of the `<video />` element. Sets internal state to `true` which changes the value of `animation`.
     */
    const handleCanPlayThrough = () => {
        setShowVideo(true);
    };

    return {
        animation: showVideo ? 'show' : 'hide',
        handleCanPlayThrough
    };
};