import {Col, media, Row, Spacer, spacing, translucify} from '@nfq/react-grid';
import Image from 'next/image';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {Button} from 'UI/components/action/ultra/Button';
import {Container} from 'UI/components/layout/ultra/Container';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {Section} from 'UI/components/layout/ultra/Section';
import {Text} from 'UI/components/layout/ultra/Text';

import {Accessories} from 'Images/ultra';

/**
 * The accessory section component.
 *
 * @returns The rendered accessory section component.
 */
export const AccessorySection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.accessory'});

    return (
        <Section>
            <StyledContainer>
                <Well hasNoGap>
                    <TextCol justify="flex-end" lg={6} xs={12}>
                        <Content>
                            <StyledHeadline $size="sm">{t('headline')}</StyledHeadline>
                            <Spacer y={4} />
                            <StyledText>{t('text')}</StyledText>
                            <Spacer y={{lg: 20, xs: 8}} />
                        </Content>
                        <StyledButton forwardedAs="link" href="/cct-evo/ultra">{t('ctaLabel')}</StyledButton>
                    </TextCol>
                    <Col align="center" justify="center" lg={6} xs={12}>
                        <StyledImage alt="" role="presentation" src={Accessories} />
                    </Col>
                </Well>
            </StyledContainer>
        </Section>
    );
};

const StyledContainer = styled(Container)`
    max-width: 1395px;
`;

const Well = styled(Row)`
    background: ${({theme}) => `linear-gradient(229deg, ${translucify(theme.colors.ultraPageForeground, 0)} 0%, ${translucify(theme.colors.ultraPageForeground, 0)} 66%), ${theme.colors.ultraAccessoriseBackgroundColor}`};
    background-image: url("/images/ultra/Accessory_BG.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    ${media('md')} {
        border-radius: 32px;
    }
    ${media('lg')} {
        background-color: ${({theme}) => translucify(theme.colors.ultraPageForeground, 5)};
        background-image: url("/images/ultra/Accessory_BG_Desktop.svg");
        background-position: 100% center;
        background-size: contain;
    }

    ${media('xl')} {
        background-position: 140% center;
        min-height: 63rem;
    }
`;

const TextCol = styled(Col)`
    min-height: 32rem;
    padding: ${spacing(15.75)} ${spacing(6)} ${spacing(6)};
    z-index: 1;

    ${media('md')} {
        min-height: 40rem;
    }

    ${media('lg')} {
        padding: ${spacing(16.25)} 0 ${spacing(20)} ${spacing(20)};
    }
`;

const Content = styled.div`
    max-width: 80%;

    ${media('lg')} {
        max-width: 100%;
    }
`;

const StyledHeadline = styled(Headline)`
    ${media('xl')} {
        font-size: 4.8rem;
    }
`;

const StyledButton = styled(Button)`
    background: ${({theme}) => theme.colors.ultraPageForeground};

    ${media('md')} {
        max-width: 36rem;
    }
`;

const StyledText = styled(Text)`
    font-size: 2rem;
    font-weight: normal;

    ${media('lg')} {
        font-size: 2.6rem;
    }

    ${media('xl')} {
        font-size: 3.2rem;
    }
`;

const StyledImage = styled(Image)`
    height: auto;
    left: auto;
    max-width: 80%;
    position: absolute;
    right: -6rem;
    top: 3rem;
    width: 100%;

    ${media('sm')} {
        top: 3rem;
        max-height: 100%;
        width: auto;
    }

    ${media('lg')} {
        height: auto;
        max-width: 100%;
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
    }
`;