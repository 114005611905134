import {Children} from 'react';

import {media, Spacer, spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {useEvoFamilySlider} from 'UI/components/action/ultra/EvoFamilySlider/useEvoFamilySlider';

import type {WithChildren} from 'types/global';

/**
 * EvoFamilySlider component for displaying the CCT EVO family of bikes.
 * This component utilizes the `useEvoFamilySlider` hook to manage slider behavior. On larger displays, the slider
 * behaviour will be turned off and the slides are displayed in three columns.
 *
 * @param props          The component props.
 * @param props.children The children components to be rendered within the slider.
 *
 * @returns The JSX element representing the EvoFamilySlider component.
 *
 * @example
 * ```tsx
 * <EvoFamilySlider>
 *    <SlideComponent1 />
 *    <SlideComponent2 />
 *    ...
 * </EvoFamilySlider>
 * ```
 */
export const EvoFamilySlider = ({children}: WithChildren) => {
    const {onDotButtonClick, scrollSnaps, selectedIndex, slideRef} = useEvoFamilySlider();

    return (
        <SliderWrapper>
            <Viewport ref={slideRef}>
                <SlideContainer>
                    {Children.map(children, (child, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Slide key={index}>
                            {child}
                        </Slide>
                    ))}
                </SlideContainer>
            </Viewport>
            {scrollSnaps.length > 1 && (
                <>
                    <Spacer y={8} />
                    <Controls>
                        {scrollSnaps.map((_, index) => (
                            <DotButton
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                aria-selected={selectedIndex === index}
                                type="button"
                                onClick={() => onDotButtonClick(index)}
                            />
                        ))}
                    </Controls>
                </>
            )}
        </SliderWrapper>
    );
};

const SliderWrapper = styled.div`
    margin: auto;
    width: 100%;
`;
const Viewport = styled.div`
    width: 100%;
`;
const SlideContainer = styled.div`
    backface-visibility: hidden;
    display: flex;
    margin-left: -1rem;
    touch-action: pan-y;
`;
const Slide = styled.div`;
    flex: 0 0 90%;
    min-width: 0;
    padding-left: 1rem;

    ${media('md')} {
        flex: 0 0 calc(100% / 3)
    }
`;

const Controls = styled.div`
    display: flex;
    gap: ${spacing(4)};
    justify-content: center;
`;

const DotButton = styled.button`
    background: ${({theme}) => theme.colors.ultraPageForeground};
    border: 0 none;
    border-radius: 50%;
    display: block;
    height: 8px;
    margin: 0;
    opacity: 0.3;
    padding: 0;
    width: 8px;

    &[aria-selected="true"] {
        opacity: 1;
    }
`;