import {lighten, media} from '@nfq/react-grid';
import Scrollbars from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import type {WithChildren} from 'types/global';

/**
 * Renders the Vertical Thumb.
 *
 * @returns The Thumb.
 */
const renderThumbVertical = () => <VerticalThumb data-cy="customScroll-vertical-thumb" />;

/**
 * Renders the Vertical track.
 *
 * @returns The Track.
 */
const renderTrackVertical = () => <VerticalTrack data-cy="customScroll-vertical-track" />;

interface ComponentProps {
    onScroll?(): void;
    /**
     * The test id used for automated testing.
     */
    testId: string;
}

/**
 * `CustomModalScrollContainer` is a React component that provides a customizable scrollable wrapper for its children.
 * It allows setting a maximum height for the auto-height property and customizes the vertical scroll bar.
 *
 * @param props          The component props.
 * @param props.testId   The test id used for automated testing.
 * @param props.children The children components that should be rendered as part of the `CustomModalScrollContainer` component.
 * @returns A customizable scrollable wrapper for the children components.
 *
 * @example
 * ```tsx
 * <CustomModalScrollContainer testId="CustomModalScrollContainer">
 *   <ChildComponent />
 * </CustomModalScrollContainer>
 * ```
 */
const CustomModalScrollContainer = ({children, testId}: WithChildren<ComponentProps>) => (
    <Wrapper
        autoHeightMax="100%"
        autoHeightMin="100%"
        data-cy={testId}
        renderThumbVertical={renderThumbVertical}
        renderTrackVertical={renderTrackVertical}
        autoHeight
        autoHide
        hideTracksWhenNotNeeded
        universal
    >
        <PaddingWrapper>
            {children}
        </PaddingWrapper>
    </Wrapper>
);

CustomModalScrollContainer.displayName = 'CustomModalScrollContainer';
CustomModalScrollContainer.defaultProps = {
    height: undefined,
    testId: undefined
};

export {CustomModalScrollContainer};

const Wrapper = styled(Scrollbars)`
    width: 100%;
`;

const PaddingWrapper = styled.div`
    padding: 0 16px 40px 16px;

    ${media('md')} {
        padding: 0 80px 80px 80px;
    }
`;

const VerticalThumb = styled.div`
    background-color: ${({theme}) => theme.colors.pageBackground};
    border-radius: inherit;
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
`;

const VerticalTrack = styled.div`
    background-color: ${({theme}) => lighten(theme.colors.ultraModalBg, 5)};
    border-radius: 2px;
    bottom: 40px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 4px;

    ${media('md')} {
        border-radius: 4px;
        width: 8px;
    }
`;