import {media, Spacer, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {ArrowButton} from 'UI/components/action/ultra/ArrowButton';
import {Text, TextHighlight} from 'UI/components/layout/ultra/Text';

import {VideoFadeAnimation} from 'UI/animations/ultra';

import {useVideoSlider} from './useVideoSlider';

const slides = [
    {
        caption: '90/380 mm',
        items: [{src: '/videos/ultra/Handlebars_90_380_02_SD.mp4'}]
    },
    {
        caption: '100/400 mm',
        items: [{src: '/videos/ultra/Handlebars_100_400_SD.mp4'}]
    },
    {
        caption: '110/420 mm',
        items: [{src: '/videos/ultra/Handlebars_110_420_SD.mp4'}]
    },
    {
        caption: '120/420 mm',
        items: [{src: '/videos/ultra/Handlebars_120_420_SD.mp4'}]
    }
];

const entry = {
    caption: '90/380 mm',
    items: [{src: '/videos/ultra/Handlebars_90_380_01_SD.mp4'}]
};

/**
 * Represents the VideoSlider component for the handlebar section responsible for displaying slider with video
 * transitions.
 * This component utilizes the useVideoSlider hook to manage slider functionality.
 * The caption for the video slide is displayed along with an arrow button for navigating to the next slide.
 *
 * @returns JSX element representing the VideoSlider component.
 *
 * @example
 * ```tsx
 * <VideoSlider />
 * ```
 */
export const VideoSlider = () => {
    const {
        animation,
        caption,
        handleCanPlayThrough,
        handleNextClick,
        handleViewportEnter,
        mediaItem,
        videoRef
    } = useVideoSlider(entry, slides);

    const {t} = useTranslation('ultra', {keyPrefix: 'page.handlebar'});

    return (
        <Wrapper>
            <VideoWrapper>
                <Video
                    ref={videoRef}
                    animate={animation}
                    src={mediaItem?.src}
                    variants={VideoFadeAnimation}
                    autoPlay
                    muted
                    playsInline
                    onCanPlayThrough={handleCanPlayThrough}
                    onViewportEnter={handleViewportEnter}
                />
            </VideoWrapper>
            <Spacer y={{lg: 10, xs: 5}} />
            <CaptionWrapper>
                <Caption>{t('dimensions')} <TextHighlight>{caption}</TextHighlight></Caption>
                <ArrowButton direction="next" onClick={handleNextClick}>
                    Next slide
                </ArrowButton>
            </CaptionWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

const VideoWrapper = styled.div`
    border-radius: 16px;
    overflow: hidden;

    ${media('lg')} {
        border-radius: 32px;
    }
`;

const Video = styled(motion.video)`
    aspect-ratio: 343 / 260;
    display: block;
    height: auto;
    object-fit: cover;
    object-position: center;
    width: 100%;

    ${media('lg')} {
        aspect-ratio: 1401 / 552;
    }
`;

const CaptionWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: ${spacing(6)};
    justify-content: end;
`;

const Caption = styled(Text)`
    font-size: 1.6rem;
    line-height: 1.3;

    ${media('lg')} {
        font-size: 2rem;
        line-height: 1.3;
    }
`;