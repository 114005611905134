import {media, spacing, translucify} from '@nfq/react-grid';
import styled from 'styled-components';

import {Container} from 'UI/components/layout/ultra/Container';
import {Section} from 'UI/components/layout/ultra/Section';

import {useVideoSection} from 'UI/modules/ultra/hooks/useVideoSection';

import {Play} from 'Images/icons/ultra';

/**
 * Represents the VideoSection component displaying the promo video.
 * It renders a section with a video element featuring controls for playback.
 *
 * @returns JSX element representing the VideoSection component.
 *
 * @example
 * ```tsx
 * <VideoSection />
 * ```
 */
export const VideoSection = () => {
    const {
        handleTriggerClick,
        promoItem,
        promoRef,
        showPromo,
        teaserItem,
        teaserRef
    } = useVideoSection();

    return (
        <Section id="experience">
            <Container>
                <VideoWrapper>
                    {showPromo
                        ? (
                            <Video
                                key="promo"
                                ref={promoRef}
                                poster="/images/ultra/UltraPromo_poster.jpeg"
                                src={promoItem?.src}
                                controls
                            />
                        ) : (
                            <Video
                                key="teaser"
                                ref={teaserRef}
                                poster="/images/ultra/UltraPromo_poster.jpeg"
                                src={teaserItem?.src}
                                autoPlay
                                loop
                                muted
                                playsInline
                            />
                        )}
                    {!showPromo && (
                        <Trigger type="button" onClick={handleTriggerClick}>
                            <TriggerContent>
                                <Play />
                                <TriggerPill>
                                    <TriggerText>Zum Video</TriggerText>
                                </TriggerPill>
                            </TriggerContent>
                        </Trigger>
                    )}
                </VideoWrapper>
            </Container>
        </Section>
    );
};

const VideoWrapper = styled.div`
    border-radius: 0;
    margin-inline-start: -${spacing(4)};
    overflow: hidden;
    position: relative;
    width: calc(100% + ${spacing(8)});

    ${media('md')} {
        border-radius: 32px;
        margin-inline-start: 0;
        overflow: hidden;
        width: 100%;
    }
`;

const Video = styled.video`
    aspect-ratio: 375 / 428;
    display: block;
    height: auto;
    object-fit: cover;
    object-position: center;
    width: 100%;

    ${media('md')} {
        aspect-ratio: 1680 / 907;
    }
`;

const Trigger = styled.button`
    align-items: center;
    background: transparent;
    border: 0 none;
    cursor: pointer;
    display: flex;
    inset: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
`;

const TriggerContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${spacing(6)};
    justify-content: center;
`;

const TriggerPill = styled.div`
    border-radius: calc(infinity * 1px);
    display: block;
    overflow: hidden;
    padding: ${spacing(3)} ${spacing(6)};
    position: relative;

    &:before {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background: ${({theme}) => translucify(theme.colors.ultraPageForeground, 20)};
        border-radius: calc(infinity * 1px);
        content: '';
        display: block;
        inset: 0;
        overflow: hidden;
        position: absolute;
        z-index: 0;
    }
`;

const TriggerText = styled.span`
    color: ${({theme}) => theme.colors.ultraPageForeground};
    font: 700 1.4rem / 1 ${({theme}) => theme.fonts.Dinot};
    position: relative;

    ${media('md')} {
        font-size: 2rem;
    }
`;