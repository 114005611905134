import type {PageSection} from 'Domain/ultra/adapters/SubnavigationAdapter';

export const PageSections: PageSection[] = [
    {
        hash: '#key-features',
        label: 'keyFeatures'
    },
    {
        hash: '#experience',
        label: 'experience'
    },
    {
        hash: '#more-models',
        label: 'moreModels'
    }
];