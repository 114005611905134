import {media, spacing} from '@nfq/react-grid';
import Image from 'next/image';
import styled from 'styled-components';

import {DanNoyola01, DanNoyola02, DanNoyola03} from 'Images/ultra';

/**
 * Represents the ImageGrid component displaying a grid of Dan Noyola shots.
 * This component renders a grid layout with multiple GridCell components containing GridImage elements.
 *
 * @returns JSX element representing the ImageGrid component.
 *
 * @example
 * ```tsx
 * <ImageGrid />
 * ```
 */
export const ImageGrid = () => (
    <Grid>
        <GridCell $colSpan={1} $colStart={1} $rowSpan={2} $rowStart={1}>
            <GridImage alt="" src={DanNoyola01} />
        </GridCell>
        <GridCell $colSpan={1} $colStart={2} $rowSpan={1} $rowStart={1}>
            <GridImage alt="" src={DanNoyola02} />
        </GridCell>
        <GridCell $colSpan={1} $colStart={2} $rowSpan={1} $rowStart={2}>
            <GridImage alt="" src={DanNoyola03} />
        </GridCell>
    </Grid>
);

const Grid = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(4)};

    ${media('lg')} {
        display: grid;
        gap: ${spacing(5)};
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
`;

interface GridCellProps {
    $colSpan: number;
    $colStart: number;
    $rowSpan: number;
    $rowStart: number;
}

const GridCell = styled.div<GridCellProps>`
    gap: 2.4rem;
    grid-column: ${({$colSpan, $colStart}) => `${$colStart} / span ${$colSpan}`};
    grid-row: ${({$rowSpan, $rowStart}) => `${$rowStart} / span ${$rowSpan}`};
    left: -2.6rem;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: calc(100% + 4.2rem);

    ${media('md')} {
        border-radius: 32px;
        left: 0;
        margin: 0;
        width: 100%;
    }
`;

const GridImage = styled(Image)`
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
`;