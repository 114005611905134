import {useState} from 'react';

import {useResponsiveVideo} from 'UI/modules/ultra/Background/useResponsiveVideo';
import {useVideoAnimation} from 'UI/modules/ultra/Background/useVideoAnimation';

import type {MediaItem} from 'Domain/ultra/entities/MediaItem';

/**
 * Custom hook that encapsulates logic to handle video playback when it enters the viewport.
 *
 * @param items An array of media items containing information about the videos to be displayed.
 * @returns An object containing animation controls, event handlers, and references related to the video.
 * @example
 * ```ts
 * const { animation, handleCanPlayThrough, handleViewportEnter, mediaItem, videoRef } = useViewportVideo(items);
 * ```
 */
export const useViewportVideo = (items: MediaItem[]) => {
    const {mediaItem, videoRef} = useResponsiveVideo(items);
    const {animation, handleCanPlayThrough} = useVideoAnimation();
    const [hasPlayed, setHasPlayed] = useState(false);

    /**
     * Handler function triggered when the video enters the viewport.
     * It attempts to play the video if it hasn't already been played.
     */
    const handleViewportEnter = async () => {
        const video = videoRef.current;

        if (!video || hasPlayed) return;

        try {
            await video.play();
            setHasPlayed(true);
        } catch (e) {
        }
    };

    return {
        animation,
        handleCanPlayThrough,
        handleViewportEnter,
        mediaItem,
        videoRef
    };
};