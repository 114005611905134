import {media} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {H2} from 'UI/components/layout/Text';
import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Eyebrow} from 'UI/components/layout/ultra/Eyebrow';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {HGroup} from 'UI/components/layout/ultra/HGroup';
import {Section as BaseSection} from 'UI/components/layout/ultra/Section';

import {BackgroundVideo} from 'UI/modules/ultra/Background/BackgroundVideo';

import {TurntableVideos} from 'Application/configs/videos/TurntableVideos';

/**
 * React component for the turntable section. It displays the turntable reveal video of the CCT EVO Ultra.
 *
 * @returns JSX element representing the TurntableSection component.
 *
 * @example
 * ```tsx
 * <TurntableSection />
 * ```
 */
export const TurntableSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.turntable'});

    return (
        <Section height="full">
            <BackgroundContainer>
                <BackgroundVideo items={TurntableVideos} />
            </BackgroundContainer>
            <Wrapper>
                <AnimateInView>
                    <HGroup align="center" size="lg">
                        <Eyebrow>{t('eyebrow')}</Eyebrow>
                        <Headline as={H2}>{t('headline')}</Headline>
                    </HGroup>
                </AnimateInView>
            </Wrapper>
        </Section>
    );
};

const Section = styled(BaseSection)`
    padding-block-start: 50vh;

    ${media('lg')} {
        padding-block-start: 60vh;
    }
`;

const BackgroundContainer = styled.div`
    inset: 0;
    position: absolute;
    z-index: 0;
`;

const Wrapper = styled(Container)`
    height: 100%;
    position: relative;
    z-index: 1;
`;