import {Col, media, Row, spacing} from '@nfq/react-grid';
import {getImageProps} from 'next/image';
import {Trans, useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Section as BaseSection} from 'UI/components/layout/ultra/Section';
import {Text, TextHighlight} from 'UI/components/layout/ultra/Text';

import {AeroBG} from 'Images/ultra';
import {getBackgroundImage} from 'UI/utils/getBackgroundImage';

/**
 * Represents the Aero Section component.
 * This component displays the aero section with text content.
 * It uses translation keys prefixed with 'page' to localize its content.
 *
 * @returns The JSX element for the AeroSection component.
 */
export const AeroSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page'});

    const {props: {srcSet}} = getImageProps({
        alt: '',
        src: AeroBG
    });
    const bg = getBackgroundImage(srcSet);

    return (
        <Section height="full" center>
            <Background $image={bg} />
            <StyledContainer>
                <Row justify="center">
                    <Col lg={10} xs={12} xxl={8}>
                        <AnimateInView>
                            <Text $size="lg">
                                <Trans components={{highlight: <TextHighlight />}} i18nKey="aero" t={t} />
                            </Text>
                        </AnimateInView>
                    </Col>
                </Row>
            </StyledContainer>
        </Section>
    );
};

const Section = styled(BaseSection)`
    padding-block: ${spacing(64)};

    ${media('lg')} {
        padding-block: ${spacing(85)};
    }
`;

const Background = styled.div<{$image: string}>`
    background: transparent ${({$image}) => $image} 100% 50%;
    background-size: cover;
    inset: 0;
    position: absolute;
    z-index: 0;
`;

const StyledContainer = styled(Container)`
    position: relative;
    z-index: 1;
`;