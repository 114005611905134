/**
 * Generates a CSS background image value using the provided source set string.
 * It constructs an image-set() CSS function string with URLs and device pixel ratios from the provided source set.
 *
 * @param srcSet The source set string containing URLs and device pixel ratios. If not provided, an empty string is used.
 * @returns A CSS background image value with image-set() function containing URLs and device pixel ratios.
 *
 * @example
 * ```ts
 * const backgroundImage = getBackgroundImage('/path/to/image.jpg 1x, /path/to/image@2x.jpg 2x');
 * // Returns: 'image-set(url("/path/to/image.jpg") 1x, url("/path/to/image@2x.jpg") 2x)'
 * ```
 */
export const getBackgroundImage = (srcSet = '') => {
    const imageSet = (srcSet)
        .split(', ')
        .map(str => {
            const [url, dpi] = str.split(' ');

            return `url("${url}") ${dpi}`;
        })
        .join(', ');

    return `image-set(${imageSet})`;
};