import {Col, media, Row} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Eyebrow} from 'UI/components/layout/ultra/Eyebrow';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {HGroup} from 'UI/components/layout/ultra/HGroup';
import {Section} from 'UI/components/layout/ultra/Section';

import {BackgroundVideo} from 'UI/modules/ultra/Background/BackgroundVideo';

import {HeroVideos} from 'Application/configs/videos/HeroVideos';

/**
 * Represents the HeroSection component.
 * This component displays a hero section with a background video and text content.
 * It uses translation keys prefixed with 'page.hero' to localize its content.
 *
 * @returns The JSX element for the HeroSection component.
 */
// eslint-disable-next-line max-lines-per-function
export const HeroSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.hero'});

    return (
        <StyledSection height="full" center>
            <Background>
                <BackgroundVideo
                    items={HeroVideos}
                    loop
                />
            </Background>
            <Wrapper>
                <Row justify="center">
                    <Col md={10} xl={6} xs={12}>
                        <AnimateInView>
                            <HGroup align="center" size="lg">
                                <Eyebrow>{t('eyebrow')}</Eyebrow>
                                <Headline>{t('headline')}</Headline>
                            </HGroup>
                        </AnimateInView>
                    </Col>
                </Row>
            </Wrapper>
        </StyledSection>
    );
};

const StyledSection = styled(Section)`
    min-height: calc(100vh - 9.7rem);

    ${media('md')} {
        min-height: calc(100vh - 12.8rem);
    }
`;

const Background = styled.div`
    inset: 0;
    position: absolute;
    z-index: 0;
`;

const Wrapper = styled(Container)`
    height: 100%;
    margin-top: 35vh;
    position: relative;
    z-index: 1;

    ${media('lg')} {
        margin-top: auto;
    }
`;