import {Col as BaseCol, media, Row, Spacer} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Eyebrow} from 'UI/components/layout/ultra/Eyebrow';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {HGroup} from 'UI/components/layout/ultra/HGroup';
import {Section} from 'UI/components/layout/ultra/Section';
import {Text} from 'UI/components/layout/ultra/Text';
import {ViewportVideo} from 'UI/components/video/ViewportVideo/ViewportVideo';

import {CockpitVideos} from 'Application/configs/videos/CockpitVideos';
import {ForkVideos} from 'Application/configs/videos/ForkVideos';

/**
 * React component for the front detail section. The front detail section shows the cockpit and fork of the CCT EVO
 * Ultra in more detail.
 *
 * @returns A React component representing the front detail section.
 *
 * @example
 * ```tsx
 * <FrontDetailSection />
 * ```
 */
export const FrontDetailSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.front'});

    return (
        <Section height="full">
            <Container>
                <AnimateInView>
                    <Row align="flex-end" direction="column">
                        <Col lg={5}>
                            <HGroup>
                                <Eyebrow>{t('eyebrow1')}</Eyebrow>
                                <Headline>{t('headline1')}</Headline>
                            </HGroup>
                            <Spacer y={{lg: 12, xs: 8}} />
                            <Text>{t('copy1')}</Text>
                        </Col>
                        <Spacer y={{lg: 40, xs: 14}} />
                        <Col lg={9}>
                            <VideoWrapper>
                                <ViewportVideo items={CockpitVideos} loop />
                            </VideoWrapper>
                        </Col>
                    </Row>
                </AnimateInView>
                <Spacer y={{lg: 60, xl: 80, xs: 30}} />
                <AnimateInView>
                    <Row direction="column">
                        <Col lg={5}>
                            <HGroup>
                                <Eyebrow>{t('eyebrow2')}</Eyebrow>
                                <Headline>{t('headline2')}</Headline>
                            </HGroup>
                            <Spacer y={{lg: 12, xs: 8}} />
                            <Text>{t('copy2')}</Text>
                        </Col>
                        <Spacer y={{lg: 30, xs: 14}} />
                        <Col lg={9}>
                            <VideoWrapper>
                                <ViewportVideo items={ForkVideos} loop />
                            </VideoWrapper>
                        </Col>
                    </Row>
                </AnimateInView>
            </Container>
        </Section>
    );
};

const Col = styled(BaseCol)`
    width: 100%;
`;

const VideoWrapper = styled.div`
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    ${media('md')} {
        border-radius: 32px;
    }
`;