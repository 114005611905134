/* eslint-disable max-len */
import React from 'react';

interface ArrowRightSmallProps {
    /**
     * A string representing the CSS class to be applied to the ArrowRightSmallIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the ArrowRightSmallIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the ArrowRightSmallIcon element.
     */
    width?: number | string;
}

/**
 * The `ArrowRightSmall` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ArrowRightSmallProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the ArrowRightSmallIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the ArrowRightSmallIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the ArrowRightSmallIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ArrowRightSmallComponent = <ArrowRightSmall className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const ArrowRightSmall = React.forwardRef<SVGSVGElement, ArrowRightSmallProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 17 16"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M3.83 8h9.34M8.5 3.33 13.17 8 8.5 12.67" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.33" />
        </svg>
    );
});

ArrowRightSmall.displayName = 'ArrowRightSmall';
ArrowRightSmall.defaultProps = {
    className: '',
    color1: '#fff',
    height: 16,
    testId: 'ArrowRightSmall',
    width: 17
};