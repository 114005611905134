import {useState} from 'react';

/**
 * The `useSubnavigation` hook is designed to manage the visibility state of the subnavigation dropdown
 * component. It provides a set of functions and state variables to control the visibility of the
 * subnavigation dropdown, ensuring that it is shown or hidden based on the current screen size and
 * user interactions. This hook is intended to be used in conjunction with the `Subnavigation` component
 * to enable responsive navigation elements in the CCT EVO Ultra campaign landing page.
 *
 * @returns An object containing the visibility state of the subnavigation dropdown and a function to toggle its visibility.
 */
export const useSubnavigation = () => {
    const [navigationIsShowing, setNavigationIsShowing] = useState(false);
    /**
     * Toggles the visibility state of the navigation dropdown. This function inverses the current
     * visibility state of the dropdown menu, enabling it to switch between shown and hidden with
     * each call.
     *
     * @example
     * ```ts
     * <button onClick={toggleDropdown}>Toggle Navigation</button>
     * ```
     */
    const toggleDropdown = () => {
        setNavigationIsShowing(!navigationIsShowing);
    };

    return {
        navigationIsShowing,
        toggleDropdown
    };
};