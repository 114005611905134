import {media, Spacer, spacing, translucify} from '@nfq/react-grid';
import Image from 'next/image';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {EvoFamilySlider} from 'UI/components/action/ultra/EvoFamilySlider/EvoFamilySlider';
import {H2} from 'UI/components/layout/Text';
import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Eyebrow} from 'UI/components/layout/ultra/Eyebrow';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {HGroup} from 'UI/components/layout/ultra/HGroup';
import {Section} from 'UI/components/layout/ultra/Section';

import {useModal} from 'UI/hooks/useModal';
import {DetailsModal} from 'UI/modules/ultra/modals/DetailsModal';

import {BK29100} from 'Application/configs/bikes/BK29100';
import {BK29101} from 'Application/configs/bikes/BK29101';
import {BK29102} from 'Application/configs/bikes/BK29102';
import {CCTEVOFactory, CCTEVOPro, CCTEVORace} from 'Images/ultra';

/**
 * React component for the CCT EVO family section component.
 * It displays the three bikes belonging to the CCT EVO line either as three columns or a small slider depending on screen size.
 *
 * @returns The JSX element representing the EvoFamilySection component.
 *
 * @example
 * ```tsx
 * <EvoFamilySection />
 * ```
 */
export const EvoFamilySection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.family'});
    const {Modal: ModalFactory, openModal: openModalFactory} = useModal();
    const {Modal: ModalPro, openModal: openModalPro} = useModal();
    const {Modal: ModalRace, openModal: openModalRace} = useModal();

    return (
        <Section id="more-models">
            <Container>
                <AnimateInView>
                    <HGroup align="center">
                        <Eyebrow>{t('eyebrow')}</Eyebrow>
                        <Headline as={H2}>{t('headline')}</Headline>
                    </HGroup>
                </AnimateInView>
                <Spacer y={{lg: 40, xs: 13}} />
            </Container>
            <Container>
                <EvoFamilySlider>
                    <Trigger onClick={openModalFactory}>
                        <BikeImage alt={t('cctEvoFactory.alt')} src={CCTEVOFactory} />
                        <Spacer y={{md: 30, xs: 10}} isNotStretching />
                        <Name>{t('cctEvoFactory.name')}</Name>
                    </Trigger>
                    <Trigger onClick={openModalPro}>
                        <Ribbon>
                            <RibbonHighlight>{t('ribbon.line1')}</RibbonHighlight>
                            {t('ribbon.line2')}
                        </Ribbon>
                        <BikeImage alt={t('cctEvoPro.alt')} src={CCTEVOPro} />
                        <Spacer y={{md: 30, xs: 10}} isNotStretching />
                        <Name>{t('cctEvoPro.name')}</Name>
                    </Trigger>
                    <Trigger onClick={openModalRace}>
                        <BikeImage alt={t('cctEvoRace.alt')} src={CCTEVORace} />
                        <Spacer y={{md: 30, xs: 10}} isNotStretching />
                        <Name>{t('cctEvoRace.name')}</Name>
                    </Trigger>
                </EvoFamilySlider>
            </Container>
            <ModalFactory>
                <DetailsModal bike={BK29100} />
            </ModalFactory>
            <ModalPro>
                <DetailsModal bike={BK29101} />
            </ModalPro>
            <ModalRace>
                <DetailsModal bike={BK29102} />
            </ModalRace>
            <Spacer y={{lg: 20, xs: 15}} />
        </Section>
    );
};

const Trigger = styled.button`
    align-items: center;
    background: ${({theme}) => translucify(theme.colors.ultraPageForeground, 5)};
    border: 0 none;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: start;
    outline: 0 none;
    padding: ${spacing(20)} ${spacing(4)} ${spacing(9)};
    position: relative;
    width: 100%;

    @media (hover: hover) {
        &:before {
            border: 1px ${({theme}) => translucify(theme.colors.ultraPageForeground, 30)} solid;
            border-radius: 16px;
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 350ms ease-in-out;
            width: 100%;
            z-index: 0;
        }

        &:hover:before {
            opacity: 1;
        }
    }

    &:focus-visible:before {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
        opacity: 1;
    }

    ${media('md')} {
        border-radius: 32px;
        padding: ${spacing(42)} ${spacing(12)} ${spacing(22)};
        @media (hover: hover) {
            &:before {
                border-radius: 32px;
            }
        }
    }
`;

const Ribbon = styled.div`
    background: ${({theme}) => theme.colors.ultraSecondaryColor};
    border-radius: 16px 16px 0 0;
    color: ${({theme}) => theme.colors.ultraPageBackground};
    font-size: 1.2rem;
    left: 0;
    line-height: 1.3;
    padding: ${spacing(1)};
    position: absolute;
    top: 0;
    width: 100%;

    ${media('md')} {
        border-radius: 32px 32px 0 0;
        font-size: 1.6rem;
        padding: ${spacing(3.5)};
    }
`;

const RibbonHighlight = styled.b`
    display: block;
    font-weight: 500;
`;

const BikeImage = styled(Image)`
    display: block;
    height: auto;
    position: relative;
    transition: transform 350ms ease-in-out;
    width: 100%;

    @media (hover: hover) {
        ${Trigger}:hover & {
            transform: scale(1.10);
        }
    }
`;

const Name = styled.div`
    color: ${({theme}) => theme.colors.ultraPageForeground};
    display: block;
    font: 700 2.4rem / 1 ${({theme}) => theme.fonts.Dinot};
    position: relative;
    text-transform: uppercase;
`;