import type {MouseEvent} from 'react';
import {useCallback, useEffect, useState} from 'react';

import {useResponsiveVideo} from 'UI/modules/ultra/Background/useResponsiveVideo';

import {PromoVideos} from 'Application/configs/videos/PromoVideos';

/**
 * Custom hook for managing video section functionality.
 * It provides methods and state variables for handling video playback and visibility.
 *
 * @returns An object containing functions and state variables related to the video section.
 *
 * @example
 * ```ts
 * const { handleTriggerClick, onPromoMount, promoItem, showPromo, teaserItem, teaserRef } = useVideoSection();
 * ```
 */
export const useVideoSection = () => {
    const {
        mediaItem: teaserItem,
        videoRef: teaserRef
    } = useResponsiveVideo([{src: '/videos/ultra/Ultra_Teaser.mp4'}]);
    const {mediaItem: promoItem, videoRef: promoRef} = useResponsiveVideo(PromoVideos);

    const [showPromo, setShowPromo] = useState(false);

    /**
     * Handles the click event on the trigger element to show the promo video.
     *
     * @param e The mouse event object.
     */
    const handleTriggerClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowPromo(true);
    };

    const [didPlay, setDidPlay] = useState(false);

    /**
     * Callback function invoked when the promo video is mounted.
     * It attempts to play the video and sets the `didPlay` state accordingly.
     *
     * @param video The mounted video element.
     */
    const handlePromoMount = useCallback(async () => {
        if (!promoRef.current || didPlay) return;

        try {
            await promoRef.current.play();
            setDidPlay(true);
        } catch (e) {
        }
    }, [didPlay, promoRef]);

    useEffect(() => {
        void handlePromoMount();
    }, [handlePromoMount, promoRef]);

    return {
        handleTriggerClick,
        promoItem,
        promoRef,
        showPromo,
        teaserItem,
        teaserRef
    };
};