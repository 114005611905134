// eslint-disable-next-line import/extensions
import Race from 'Images/ultra/CCTEVORace.png';

import type {Bike} from 'Domain/ultra/entities/Bikes';

export const BK29102: Bike = {
    description: 'BK29102description',
    features: ['WHEELS_28', 'DI2_READY', 'TAP_HEADTUBE', 'INT_CABLE', 'THRU_AXLE'],
    id: 'BK29102',
    image: Race,
    info: [
        {
            label: 'materialLabel',
            value: 'Carbon'
        },
        {
            label: 'weightLabel',
            value: '7,6 KG'
        },
        {
            label: 'colorsLabel',
            value: [
                {
                    color: '#0E62B7',
                    name: 'Pearlized Black / Reflex Blue'
                },
                {
                    color: '#B25C42',
                    name: 'Pearlized Black / Copper'
                }
            ]
        },
        {
            label: 'frameLabel',
            value: '46 | 48 | 51 | 54 | 57 | 60'
        }
    ],
    link: 'https://www.corratec.com/bikes/road/race-carbon/bike/race-bk29102/',
    name: 'CCT EVO Race',
    price: 5999,
    specs: [
        {
            headline: 'baseLabel',
            items: [
                {
                    title: 'baseFrameLabel',
                    value: ['cctEvoCarbon']
                },
                {
                    title: 'baseForkLabel',
                    value: ['cctProFork']
                },
                {
                    title: 'baseBrakesLabel',
                    value: ['sramRival160Front', 'sramRival160Rear']
                },
                {
                    title: 'baseStemLabel',
                    value: ['stemCarbonInt']
                },
                {
                    title: 'baseHandlebarLabel',
                    value: ['roadCarbonHandle']
                },
                {
                    title: 'baseSaddleLabel',
                    value: ['selleNovusBoostEvo']
                },
                {
                    title: 'baseSeatpostLabel',
                    value: ['carbonAero']
                }
            ]
        },
        {
            headline: 'wheelsLabel',
            items: [
                {
                    title: 'wheelsRimLabel',
                    value: ['ursusMiuraFront', 'ursusMiuraRear']
                },
                {
                    title: 'wheelsTireLabel',
                    value: ['tire700x26Front', 'tire700x26Rear']
                }
            ]
        },
        {
            headline: 'driveLabel',
            items: [
                {
                    title: 'driveCrankLabel',
                    value: ['crankSramRivalDub46']
                },
                {
                    title: 'driveCassetteLabel',
                    value: ['cassetteSramXG1250']
                },
                {
                    title: 'driveFrontDerailleurLabel',
                    value: ['frontDerailleurSramRival']
                },
                {
                    title: 'driveRearDerailleurLabel',
                    value: ['rearDerailleurSramRival']
                },
                {
                    title: 'driveShifterLabel',
                    value: ['shifterSramRival']
                }
            ]
        }
    ]
};