import {useEffect, useRef, useState} from 'react';
import type {Dispatch, RefObject, SetStateAction} from 'react';

import {useAnimationControls} from 'framer-motion';

/**
 * Hook for managing modal animation.
 *
 * This hook provides functionality for managing the animation of a modal component.
 * It controls the opening and closing animation of the modal based on the provided
 * `open` state. The hook returns the animation controls and modal ref to be used in
 * the modal component.
 *
 * @param state Ref object for managing the modal state.
 * @returns The animation controls and modal ref.
 *
 * @example
 * ```tsx
 * const {controls, modal} = useModalAnimation(state);
 * ```
 */
export const useModalAnimation = (state: RefObject<Dispatch<SetStateAction<boolean>>>) => {
    const [open, setOpen] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const modal = useRef<HTMLDialogElement>(null);
    const controls = useAnimationControls();

    // @ts-expect-error
    // eslint-disable-next-line no-param-reassign
    state.current = setOpen;

    useEffect(() => {
        const modalDialog = modal.current;

        /**
         * Run the animation to show or hide the modal.
         *
         * This function triggers the animation for showing or hiding the modal
         * based on the current `open` state. If `open` is `true`, the modal is
         * displayed using the `showModal` method and the enter animation is
         * started. If `open` is `false`, the exit animation is started and the
         * modal is closed.
         */
        const runAnimation = async () => {
            if (open) {
                setShowContent(true);
                modalDialog?.showModal();
                modalDialog?.style.setProperty('display', 'grid');
                document.body.classList.add('scroll-lock');
                await controls.start('enter');
            } else {
                document.body.classList.remove('scroll-lock');
                await controls.start('exit');
                modalDialog?.close();
                modalDialog?.style.setProperty('display', 'none');
                setShowContent(false);
            }
        };

        modalDialog?.addEventListener('close', () => setOpen(false));

        void runAnimation();

        return () => {
            modalDialog?.removeEventListener('close', () => setOpen(false));
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controls, open]);

    return {
        controls,
        modal,
        setOpen,
        showContent
    };
};