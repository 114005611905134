import {Col, media, Row, Spacer, spacing} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {H2} from 'UI/components/layout/Text';
import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Eyebrow} from 'UI/components/layout/ultra/Eyebrow';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {HGroup} from 'UI/components/layout/ultra/HGroup';
import {Section} from 'UI/components/layout/ultra/Section';
import {Text} from 'UI/components/layout/ultra/Text';

/**
 * React component for rendering the unique section. It showcases the limited quantity, frame sizes, weight, and color
 * variations for the CCT EVO Ultra.
 *
 * @returns A React component representing the unique section.
 *
 * @example
 * ```tsx
 * <UniqueSection />
 * ```
 */
export const UniqueSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.unique'});

    const facts = t('facts', {returnObjects: true});

    return (
        <Section>
            <Container>
                <Row justify="center">
                    <Col lg={8} md={10} xs={12}>
                        <AnimateInView>
                            <HGroup align="center">
                                <Eyebrow>{t('eyebrow')}</Eyebrow>
                                <Headline as={H2}>{t('headline')}</Headline>
                            </HGroup>
                        </AnimateInView>
                        <Spacer y={{lg: 12, xs: 8}} />
                        <AnimateInView>
                            <Text $align="center">{t('text')}</Text>
                        </AnimateInView>
                    </Col>
                </Row>
                <Spacer y={{lg: 38, xs: 14}} />
                <AnimateInView>
                    <FactList>
                        {facts.map(fact => (
                            <FactItem key={fact.headline}>
                                <FactKey>{fact.headline}</FactKey>
                                <Spacer y={{lg: 8, xs: 6}} />
                                <FactValue>{fact.value}</FactValue>
                            </FactItem>
                        ))}
                    </FactList>
                </AnimateInView>
            </Container>
            <Spacer y={{lg: 30, xl: 40, xs: 15}} />
        </Section>
    );
};

const FactList = styled.dl`
    --gap: ${spacing(8)};
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin: 0;

    ${media('lg')} {
        --gap: ${spacing(5)};
        flex-direction: row;
    }
`;

const FactItem = styled.div`
    flex: 0 0 100%;
    max-width: 100%;

    ${media('lg')} {
        flex-basis: calc(25% - var(--gap) + var(--gap) / 4);
        max-width: calc(25% - var(--gap) + var(--gap) / 4);
    }
`;

const FactKey = styled.dt`
    color: ${({theme}) => theme.colors.ultraPageForeground};
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1;
    text-align: center;

    ${media('lg')} {
        font-size: 2.4rem;
    }
`;

const FactValue = styled.dd`
    color: ${({theme}) => theme.colors.ultraPageForeground};
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    text-align: center;

    ${media('lg')} {
        font-size: 6.4rem;
    }
`;