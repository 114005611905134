import {Col, media, Row, Spacer} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {H2} from 'UI/components/layout/Text';
import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Eyebrow} from 'UI/components/layout/ultra/Eyebrow';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {HGroup} from 'UI/components/layout/ultra/HGroup';
import {Section} from 'UI/components/layout/ultra/Section';
import {Text} from 'UI/components/layout/ultra/Text';
import {ViewportVideo} from 'UI/components/video/ViewportVideo';

import {FrameDetail1Videos, FrameDetail2Videos, FrameDetail3Videos} from 'Application/configs/videos/FrameDetailVideos';

import type {Breakpoints, DirectionObject} from '@nfq/react-grid';

/**
 * Renders the frame detail section component.
 *
 * @returns The rendered frame detail section component.
 *
 * @example
 * ```tsx
 * <FrameDetailSection />
 * ```
 */
export const FrameDetailSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.frame'});

    const direction = {
        lg: 'row',
        xs: 'column'
    } satisfies DirectionObject;

    const isReverse = ['xs', 'sm', 'md'] satisfies Breakpoints[];

    return (
        <Section height="full" id="key-features">
            <Spacer y={{lg: 30, xl: 40, xs: 15}} />
            <Container>
                <AnimateInView>
                    <Row
                        direction={direction}
                        justify="space-between"
                        isReverse
                    >
                        <Col lg={6}>
                            <VideoWrapper>
                                <ViewportVideo items={FrameDetail1Videos} loop />
                            </VideoWrapper>
                        </Col>
                        <Col justify="flex-start" lg={5}>
                            <HGroup>
                                <Eyebrow>{t('eyebrow')}</Eyebrow>
                                <Headline as={H2}>{t('headline1')}</Headline>
                            </HGroup>
                            <Spacer y={{lg: 12, xs: 8}} isNotStretching />
                            <Text>{t('copy1')}</Text>
                        </Col>
                    </Row>
                </AnimateInView>
                <Spacer y={{lg: 60, xl: 80, xs: 30}} />
                <AnimateInView>
                    <Row direction={direction} isReverse={isReverse} justify="space-between">
                        <Col lg={6}>
                            <VideoWrapper>
                                <ViewportVideo items={FrameDetail2Videos} loop />
                            </VideoWrapper>
                        </Col>
                        <Col lg={5}>
                            <HGroup>
                                <Eyebrow>{t('eyebrow')}</Eyebrow>
                                <Headline as={H2}>{t('headline2')}</Headline>
                            </HGroup>
                        </Col>
                    </Row>
                </AnimateInView>
                <Spacer y={{lg: 60, xl: 80, xs: 30}} />
                <AnimateInView>
                    <Row
                        direction={direction}
                        justify="space-between"
                        isReverse
                    >
                        <Col lg={6}>
                            <VideoWrapper>
                                <ViewportVideo items={FrameDetail3Videos} loop />
                            </VideoWrapper>
                        </Col>
                        <Col lg={5}>
                            <HGroup>
                                <Eyebrow>{t('eyebrow')}</Eyebrow>
                                <Headline as={H2}>{t('headline3')}</Headline>
                            </HGroup>
                        </Col>
                    </Row>
                </AnimateInView>
            </Container>
        </Section>
    );
};

const VideoWrapper = styled.div`
    border-radius: 16px;
    overflow: hidden;
    width: 100%;

    ${media('md')} {
        border-radius: 32px;
    }
`;