import {media, spacing} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {Button} from 'UI/components/action/ultra/Button';

import {PageSections} from 'Application/configs/PageSections';

/**
 * The subnavigation component for the CCT EVO Ultra campaign.
 * This component is designed to display the subnavigation elements
 * on larger screens, providing a set of links to navigate to different.
 *
 * @returns The JSX element for the subnavigation component.
 */
export const SubnavigationDesktop = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'subnavigation'});

    return (
        <AnchorWrapper>
            <StyledHomeButton forwardedAs="link" href="#" size="small" variant="secondary">
                <b>CCT Evo ultra</b>
            </StyledHomeButton>
            {PageSections.map(section => (
                <StyledButton
                    key={section.hash}
                    forwardedAs="link"
                    href={section.hash}
                    size="small"
                    variant="secondary"
                >
                    {t(section.label)}
                </StyledButton>
            ))}
        </AnchorWrapper>
    );
};

const AnchorWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 0;
    justify-content: flex-start;

    ${media('lg')} {
        gap: ${spacing(8)};
    }
`;

const StyledButton = styled(Button)`
    background-color: transparent;
    border-radius: .8rem;
    font-weight: 400;
    opacity: .5;
    padding: ${spacing(2)};

    &:focus,
    &:hover {
        background-color: transparent;
        border-color: transparent;
        opacity: 1;
    }

    &:focus-visible {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
    }
`;

const StyledHomeButton = styled(StyledButton)`
    color: ${({theme}) => theme.colors.ultraPageForeground};
    font-weight: 700;
    opacity: 1;

    &:hover {
        opacity: .5;
    }
`;