export const FrameDetail1Videos = [
    {
        media: '(min-width: 1400px)',
        src: '/videos/ultra/Frame_Detail_1_FHD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(min-width: 1400px)',
        src: '/videos/ultra/Frame_Detail_1_FHD.mp4'
    },
    {
        media: '(min-width: 1000px)',
        src: '/videos/ultra/Frame_Detail_1_HD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(min-width: 1000px)',
        src: '/videos/ultra/Frame_Detail_1_HD.mp4'
    },
    {
        src: '/videos/ultra/Frame_Detail_1_SD.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {src: '/videos/ultra/Frame_Detail_1_SD.mp4'}
];

export const FrameDetail2Videos = [
    {
        media: '(min-width: 1400px)',
        src: '/videos/ultra/Frame_Detail_2_FHD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(min-width: 1400px)',
        src: '/videos/ultra/Frame_Detail_2_FHD.mp4'
    },
    {
        media: '(min-width: 1000px)',
        src: '/videos/ultra/Frame_Detail_2_HD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(min-width: 1000px)',
        src: '/videos/ultra/Frame_Detail_2_HD.mp4'
    },
    {
        src: '/videos/ultra/Frame_Detail_2_SD.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {src: '/videos/ultra/Frame_Detail_2_SD.mp4'}
];

export const FrameDetail3Videos = [
    {
        media: '(min-width: 1400px)',
        src: '/videos/ultra/Frame_Detail_3_FHD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(min-width: 1400px)',
        src: '/videos/ultra/Frame_Detail_3_FHD.mp4'
    },
    {
        media: '(min-width: 1000px)',
        src: '/videos/ultra/Frame_Detail_3_HD_av1.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {
        media: '(min-width: 1000px)',
        src: '/videos/ultra/Frame_Detail_3_HD.mp4'
    },
    {
        src: '/videos/ultra/Frame_Detail_3_SD.mp4',
        type: 'video/mp4; codecs="av01.0.05M.08"'
    },
    {src: '/videos/ultra/Frame_Detail_3_SD.mp4'}
];