import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import type {Variants} from 'framer-motion';
import type {WithChildren} from 'types/global';

const variants: Variants = {
    hidden: {
        opacity: 0,
        y: '100px'
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        },
        y: 0
    }
};

/**
 * The AnimateInView component applies a vertical slide animation to its children when they come into view.
 * It utilizes Framer Motion for animation with specified variants and transition properties.
 *
 * @param props          The component props.
 * @param props.children The children elements to apply animation to.
 * @returns The JSX element for the AnimateInView component.
 *
 * @example
 * ```tsx
 * <AnimateInView>
 *    <Content />
 * </AnimateInView>
 * ```
 */
export const AnimateInView = ({children}: WithChildren) => (
    <Wrapper
        initial="hidden"
        variants={variants}
        viewport={{once: true}}
        whileInView="visible"
    >
        {children}
    </Wrapper>
);

const Wrapper = styled(motion.div)``;