export const TurntableVideos = [
    {
        media: '(orientation: landscape) and (min-width: 3000px)',
        src: '/videos/ultra/Ultra_BG_Desktop_4K.mp4'
    },
    {
        media: '(orientation: landscape) and (min-width: 2000px)',
        src: '/videos/ultra/Ultra_BG_Desktop_2K.mp4'
    },
    {
        media: '(orientation: landscape) and (min-width: 1400px)',
        src: '/videos/ultra/Ultra_BG_Desktop_FHD.mp4'
    },
    {
        media: '(orientation: landscape) and (min-width: 1000px)',
        src: '/videos/ultra/Ultra_BG_Desktop_HD.mp4'
    },
    {src: '/videos/ultra/Ultra_BG_Desktop_SD.mp4'}
];