import type {MouseEvent as ReactMouseEvent} from 'react';

import {media, translucify} from '@nfq/react-grid';
import styled from 'styled-components';

import {SROnly} from 'UI/components/layout/A11y';

import {ArrowRight} from 'Images/icons/ultra';

import type {WithChildren} from 'types/global';

interface ComponentProps {
    /**
     * The direction of the arrow button, either 'next' or 'prev'.
     */
    direction: 'next' | 'prev';
    /**
     * An optional flag to indicate if the button is disabled.
     */
    disabled?: boolean;
    /**
     * An optional event handler function for click events.
     */
    onClick?(e: ReactMouseEvent): void;
}

/**
 * Renders an arrow button component which is used in slider components, with the specified direction and an optional onClick event handler.
 *
 * @param props           The component props.
 * @param props.children  The child elements to be rendered within the button, used as the accessible label.
 * @param props.direction The direction of the arrow button.
 * @param props.disabled  An optional flag to indicate whether the button is disabled or not.
 * @param props.onClick   An optional event handler function for click events.
 * @returns The rendered button component.
 *
 * @example
 * ```tsx
 * <ArrowButton direction="next" onClick={handleNextClick}>Next</ArrowButton>
 * ```
 */
export const ArrowButton = ({children, direction, disabled, onClick}: WithChildren<ComponentProps>) => {
    const Icon = direction === 'prev' ? ArrowLeft : StyledArrowRight;

    return (
        <Wrapper disabled={disabled} type="button" onClick={onClick}>
            <Icon />
            <SROnly>{children}</SROnly>
        </Wrapper>
    );
};

const Wrapper = styled.button`
    align-items: center;
    background: transparent;
    border: 1px ${({theme}) => theme.colors.ultraPageForeground} solid;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    flex: 0 0 2.4rem;
    height: 2.4rem;
    justify-content: center;
    margin: 0;
    outline: 0 none;
    padding: 0;
    transition: all 180ms ease-in-out;
    width: 2.4rem;

    ${media('md')} {
        flex-basis: 4.8rem;
        height: 4.8rem;
        width: 4.8rem;
    }

    &:hover {
        background-color: ${({theme}) => translucify(theme.colors.ultraPageForeground, 10)}
    }

    &:focus-visible {
        border: 2px solid ${({theme}) => theme.colors.ultraFocusColor};
    }

    &:disabled {
        border-color: transparent;
        opacity: 0.5;
        pointer-events: none;
    }
`;

const StyledArrowRight = styled(ArrowRight)`
    width: 1rem;

    ${media('md')} {
        width: auto;
    }
`;

const ArrowLeft = styled(StyledArrowRight)`
    transform: rotate(180deg);
`;