import type {Variants} from 'framer-motion';

export const ModalEnterExitAnimation: Variants = {
    enter: {
        opacity: 1,
        y: '0px'
    },
    exit: {
        opacity: 0,
        y: '80px'
    },
    initial: {
        opacity: 0,
        y: '80px'
    }
};