import {Col, Row, Spacer} from '@nfq/react-grid';
import {useTranslation} from 'next-i18next';

import {AnimateInView} from 'UI/components/layout/ultra/AnimateInView';
import {Container} from 'UI/components/layout/ultra/Container';
import {Headline} from 'UI/components/layout/ultra/Headline';
import {Section} from 'UI/components/layout/ultra/Section';
import {Text} from 'UI/components/layout/ultra/Text';

import {VideoSlider} from 'UI/modules/ultra/VideoSlider/VideoSlider';

/**
 * Represents the Handlebar section component displaying a slider showcasing the different handlebar options.
 *
 * @returns JSX element representing the HandlebarSection component.
 *
 * @example
 * ```tsx
 * <HandlebarSection />
 * ```
 */
export const HandlebarSection = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'page.handlebar'});

    return (
        <Section>
            <Spacer y={{lg: 30, xl: 40, xs: 15}} />
            <Container>
                <Row justify="center">
                    <Col lg={10}>
                        <AnimateInView>
                            <Headline $align="center">{t('headline')}</Headline>
                        </AnimateInView>
                        <Spacer y={{lg: 12, xs: 8}} />
                        <AnimateInView>
                            <Row justify="center">
                                <Col lg={8}>
                                    <Text $align="center">{t('description')}</Text>
                                </Col>
                            </Row>
                        </AnimateInView>
                        <Spacer y={{lg: 40, xs: 12}} />
                        <VideoSlider />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};