// eslint-disable-next-line import/extensions
import Pro from 'Images/ultra/CCTEVOPro.png';

import type {Bike} from 'Domain/ultra/entities/Bikes';


export const BK29101: Bike = {
    description: 'BK29101description',
    features: ['WHEELS_28', 'DI2_READY', 'TAP_HEADTUBE', 'INT_CABLE', 'THRU_AXLE'],
    id: 'BK29101',
    image: Pro,
    info: [
        {
            label: 'materialLabel',
            value: 'Carbon'
        },
        {
            label: 'weightLabel',
            value: '7,6 KG'
        },
        {
            label: 'colorsLabel',
            value: [
                {
                    color: '#0E62B7',
                    name: 'Pearlized Black / Reflex Blue'
                },
                {
                    color: '#B25C42',
                    name: 'Pearlized Black / Copper'
                }
            ]
        },
        {
            label: 'frameLabel',
            value: '46 | 48 | 51 | 54 | 57 | 60'
        }
    ],
    link: 'https://www.corratec.com/bikes/road/race-carbon/bike/pro-bk29101/',
    name: 'CCT EVO Pro',
    price: 6999,
    specs: [
        {
            headline: 'baseLabel',
            items: [
                {
                    title: 'baseFrameLabel',
                    value: ['cctEvoCarbon']
                },
                {
                    title: 'baseForkLabel',
                    value: ['cctProFork']
                },
                {
                    title: 'baseBrakesLabel',
                    value: ['sramForce160Front', 'sramForce160Rear']
                },
                {
                    title: 'baseStemLabel',
                    value: ['stemCarbonInt']
                },
                {
                    title: 'baseHandlebarLabel',
                    value: ['roadCarbonHandle']
                },
                {
                    title: 'baseSaddleLabel',
                    value: ['selleNovusBoostEvo']
                },
                {
                    title: 'baseSeatpostLabel',
                    value: ['carbonAero']
                }
            ]
        },
        {
            headline: 'wheelsLabel',
            items: [
                {
                    title: 'wheelsRimLabel',
                    value: ['rim700cFront', 'rim700cRear']
                },
                {
                    title: 'wheelsTireLabel',
                    value: ['tire700x26CFront', 'tire700x26CRear']
                }
            ]
        },
        {
            headline: 'driveLabel',
            items: [
                {
                    title: 'driveCrankLabel',
                    value: ['crankSramForceDub48']
                },
                {
                    title: 'driveCassetteLabel',
                    value: ['cassetteSramXG1290']
                },
                {
                    title: 'driveFrontDerailleurLabel',
                    value: ['frontDerailleurSramForce']
                },
                {
                    title: 'driveRearDerailleurLabel',
                    value: ['rearDerailleurSramForce']
                },
                {
                    title: 'driveShifterLabel',
                    value: ['shifterSramForce']
                }
            ]
        }
    ]
};