import {type ReactNode, useMemo, useState} from 'react';

import {useResponsiveVideo} from 'UI/modules/ultra/Background/useResponsiveVideo';
import {useVideoAnimation} from 'UI/modules/ultra/Background/useVideoAnimation';

import type {MediaItem} from 'Domain/ultra/entities/MediaItem';

interface Slide {
    caption: ReactNode;
    items: MediaItem[];
}

/**
 * A custom hook for managing the functionality of the handlebar section video slider.
 * This hook takes an array of slide objects as input.
 * It maintains the current slide state and provides methods to handle navigation between slides.
 * The hook also integrates with useResponsiveVideo to handle video playback.
 *
 * @param entry  An object representing the slide to be shown on viewport enter.
 * @param slides An array of objects representing individual slides in the video slider containing the slide caption and the media items.
 * @returns An object containing properties and methods for managing the video slider.
 *
 * @example
 * ```typescript
 * const entry = {caption: 'Entry Slide', items: […]};
 *
 * const slides = [
 *     {caption: 'Slide 1', items: […]},
 *     {caption: 'Slide 2', items: […]},
 * ];
 *
 * const {caption, handleNextClick, mediaItem, videoRef} = useVideoSlider(entry, slides);
 * ```
 */
export const useVideoSlider = (entry: Slide, slides: Slide[]) => {
    const [hasPlayed, setHasPlayed] = useState(false);
    const [isFirstRun, setIsFirstRun] = useState(true);
    const [currSlideIndex, setCurrSlideIndex] = useState(0);

    const currSlides = useMemo(() => {
        if (isFirstRun) {
            const [, ...restSlides] = slides;

            return [entry, ...restSlides];
        }

        return slides;
    }, [entry, isFirstRun, slides]);

    const {caption, items} = currSlides[Number(currSlideIndex)];

    const {mediaItem, videoRef} = useResponsiveVideo(items);
    const {animation, handleCanPlayThrough} = useVideoAnimation();

    /**
     * Handler function triggered when the video enters the viewport.
     * It attempts to play the video if it hasn't already been played.
     */
    const handleViewportEnter = async () => {
        const video = videoRef.current;

        if (!video || hasPlayed) return;

        try {
            await video.play();
            setHasPlayed(true);
        } catch (e) {
        }
    };

    /**
     * Event handler for the next button click.
     */
    const handleNextClick = () => {
        if (isFirstRun) {
            setIsFirstRun(false);
        }

        setCurrSlideIndex((currSlideIndex + 1) % slides.length);
    };

    return {
        animation,
        caption,
        handleCanPlayThrough,
        handleNextClick,
        handleViewportEnter,
        mediaItem,
        videoRef
    };
};